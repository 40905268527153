import React from "react";
import { Helmet } from "react-helmet";
import "./MarketFocusPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";

function MarketFocus() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Market Focus</title>
                <meta name="description" content="As an Engineering, Procurement, Integration, Installation, and Maintenance company, the purpose of the Organization is to provide quality services in Automation & Control, Valves & Actuation, Process Solutions, Flow Measurement Solutions, Electrical & Instrumentation Solutions, and Supply Chain Services to all our Customers." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="market-image" pageTitle="Market Focus" pageDesc1="" pageDesc2="As an Engineering Procurement, Integration, Installation and Maintenance (EPiiMtce) company, the purpose of the Organization is to provide quality services in Flow Drives (Rotating Equipment), Flow Measurement Solutions, Flow Control (Valves and Actuation), and Supply Chain Services, to all our Customers." />
            <div className="market-focus col-11 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md border order-2 order-md-1 market-focus-image market-focus-image-oil">

                </div>
                <div className="col-12 col-md order-1 order-md-2 market-focus-text pt-2">
                    <div className="col-12 ps-5 market-focus-text-con">
                        <h2 className="mb-5">Oil &amp; Gas</h2>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3 standard-text">The Oil & Gas industry, with its complex processes and critical infrastructures, has certain key business imperatives that include enhanced operational excellence, market-driven price optimization, a consistent need for operational expenditure reduction, top-class safety and security systems, and many more.<br></br><br></br> Since its inception in 2009, PE Energy has steadily come to be one of the very top, most trusted and sought-after partner in the West African Oil & Gas Industry. Our team of internationally trained experts delivers not just solutions but also the application engineering expertise to solve any industry challenge. With our solution portfolio, we are in a position to address the critical requirements of the entire Oil & Gas Value Chain, covering:<br></br><br></br>
                            {/* <li className="mb-2">Upstream: Drilling, Production and Processing Facilities, FPSO, FSO, MOPU</li>
                            <li className="mb-2">Midstream: Gas Processing, Valve Terminals, Metering Stations, Tank Farms and Terminals</li>
                            <li className="mb-2">Downstream: Refineries and Petrochemicals, Retail Stations</li> */}
                        </h6>
                        <div className="col-12 col-md-10 row mx-0 mb-3">
                            <div className="col mx-1 oil-gas-sectors oil-gas-upstream">
                                <div className="sector-text px-2 upstream-sector-text pt-4">
                                    <h6>Upstream</h6>
                                    <li>Exploration</li>
                                    <li>Production</li>
                                </div>
                            </div>
                            <div className="col mx-1 oil-gas-sectors oil-gas-midstream">
                                <div className="sector-text px-2 midstream-sector-text pt-4">
                                    <h6>Midstream</h6>
                                    <li>Pipelines</li>
                                    <li>Storage Facilities</li>
                                    <li>LNG Processing</li>
                                </div>
                            </div>
                            <div className="col mx-1 oil-gas-sectors oil-gas-downstream">
                                <div className="sector-text px-2 downstream-sector-text pt-4">
                                    <h6>Downstream</h6>
                                    <li>Refineries</li>
                                    <li>Distribution and Retail</li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="market-focus col-11 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md border  order-2 order-md-2 market-focus-image market-focus-image-power">

                </div>
                <div className="col-12 col-md order-1 order-md-1 market-focus-text pt-5">
                    <div className="col-12 ps-5 market-focus-text-con">
                        <h2 className="mb-5">Power</h2>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3 standard-text">The power sector remains the driver of an industrial economy. As such, PE Energy leverages on its team of highly trained field-service engineers, which, combined with the in-depth technical experience and capacity of our partner OEMs, allow us to deliver cost-effective solutions to captive and grid-connected power producers as well as transmission and distribution companies.<br></br><br></br> PE Energy delivers new gas & steam turbine units, genuine gas & steam turbine parts and quality gas & steam turbine maintenance services to its esteemed clients.</h6>
                        <div className="col-12 col-md-10 row mx-0 mb-3">
                            <div className="col mx-1 oil-gas-sectors power-generation">
                                <div className="sector-text px-2 generation-sector-text pt-4">
                                    <h6>Generation</h6>
                                </div>
                            </div>
                            <div className="col mx-1 oil-gas-sectors power-transmission">
                                <div className="sector-text px-2 transmission-sector-text pt-4">
                                    <h6>Transmission</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="market-focus col-11 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md border order-2 order-md-1 market-focus-image market-focus-image-digital">

                </div>
                <div className="col-12 col-md order-1 order-md-2 market-focus-text pt-2">
                    <div className="col-12 ps-5 market-focus-text-con">
                        <h2 className="mb-5">Digitalization</h2>
                        <h6 className="col-12 col-md-10 mt-3 standard-text">The Next Generation of Business Intelligence isn’t siloed in systems or dependent on fancy dashboards Instead, insights appear instantly, making it easier to see change and take action. 
                        </h6>
                    </div>
                </div>
            </div> */}
            <div className="market-focus col-11 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md border  order-2 order-md-1 market-focus-image market-focus-image-food">

                </div>
                <div className="col-12 col-md order-1 order-md-2 market-focus-text pt-2">
                    <div className="col-12 ps-5">
                        <h2 className="mb-5">Process Industries</h2>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3 standard-text">Being the leading automation, process, and system integration company in Nigeria, PE Energy has evolved into a total solution provider for several process industries including the Food and Beverage industry.<br></br><br></br> Our value proposition over the years has been to provide cutting edge yet cost-effective solutions for the sustainability of the business operations of the Food and Beverage companies in Nigeria. In consideration of the criticality of the Food and Beverage industry to the Nigerian economy, PE Energy remains committed to offering solutions that will not only boost the operating margins but improve the overall bottom-line of our clients.<br></br><br></br> As such, we continue to provide high-quality valves for process control, accurate Flow Measurement Solutions for liquid, solid and gas measurement, process solutions such as Rotating Equipment to convey process fluids, electrical and instrumentation solutions to energize and monitor the factory and supply chain services to procure plant equipment required for maintenance, repairs, and operations.</h6>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-9 mx-auto px-0 market-focus-whole">
                <div className="col-12 text-center">
                    <h1 className="section-title mt-5">Other <span classname="">Industries</span></h1>
                </div>
                <Splide
                    options={{
                        rewind: true,
                        gap: '1rem',
                        type: 'loop',
                        perPage: 4,
                        breakpoints: {
                            768: {
                                perPage: 1,
                                drag: true,
                                pagination: true
                            },
                        },
                        perMove: 1,
                        autoplay: true,
                        interval: 3000,
                        arrows: false,
                        drag: true,
                        pagination: true,
                    }}
                    aria-label="My Favorite Images" X
                >
                    <SplideSlide>
                        <Link to="/market-focus" className="nav-link" href="#">
                            <div className="col-12 col-lg mx-2 market-focus-individual">
                                <div className="market-focus-icon border food-industry col-12 px-0 text-center">

                                </div>
                                <div className="market-focus-text col-12 px-5 text-center">
                                    <h1 className="market-focus-title">Food and Beverages</h1>
                                </div>
                            </div>
                        </Link>
                    </SplideSlide>
                    <SplideSlide>
                        <Link to="/market-focus" className="nav-link" href="#">
                            <div className="col-12 col-lg mx-2 market-focus-individual">
                                <div className="market-focus-icon border chemical-industry col-12 px-0 text-center">

                                </div>
                                <div className="market-focus-text col-12 px-5 text-center">
                                    <h1 className="market-focus-title">Chemicals</h1>
                                </div>
                            </div>
                        </Link>
                    </SplideSlide>
                    <SplideSlide>
                        <Link to="/market-focus" className="nav-link" href="#">
                            <div className="col-12 col-lg mx-2 market-focus-individual">
                                <div className="market-focus-icon border cement-industry col-12 px-0 text-center">

                                </div>
                                <div className="market-focus-text col-12 px-5 text-center">
                                    <h1 className="market-focus-title">Cement</h1>
                                </div>
                            </div>
                        </Link>
                    </SplideSlide>
                    <SplideSlide>
                        <Link to="/market-focus" className="nav-link" href="#">
                            <div className="col-12 col-lg mx-2 market-focus-individual">
                                <div className="market-focus-icon border marine-industry col-12 px-0 text-center">

                                </div>
                                <div className="market-focus-text col-12 px-5 text-center">
                                    <h1 className="market-focus-title">Marine</h1>
                                </div>
                            </div>
                        </Link>
                    </SplideSlide>
                    <SplideSlide>
                        <Link to="/market-focus" className="nav-link" href="#">
                            <div className="col-12 col-lg mx-2 market-focus-individual">
                                <div className="market-focus-icon border mining-industry col-12 px-0 text-center">

                                </div>
                                <div className="market-focus-text col-12 px-5 text-center">
                                    <h1 className="market-focus-title">Mining</h1>
                                </div>
                            </div>
                        </Link>
                    </SplideSlide>
                    <SplideSlide>
                        <Link to="/market-focus" className="nav-link" href="#">
                            <div className="col-12 col-lg mx-2 market-focus-individual">
                                <div className="market-focus-icon border hydraulics-industry col-12 px-0 text-center">

                                </div>
                                <div className="market-focus-text col-12 px-5 text-center">
                                    <h1 className="market-focus-title">Hydraulics</h1>
                                </div>
                            </div>
                        </Link>
                    </SplideSlide>
                </Splide>
            </div>
            <Footer />
        </div>
    );
}

export default MarketFocus;
