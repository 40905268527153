import React from "react";
import { Helmet } from "react-helmet";
import "./ProcessPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";

function Process() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Flow Drives (Rotating Equipment)</title>
                <meta name="description" content="PE Energy is renowned for providing high-quality process equipment, skids, and modules that are required for production processes. These effective and efficient process solutions include Pumps & Skid Mounted Pump Systems, Compressors & Compressor Skids, Chemical Injection Skids, Fuel Gas Conditioning Skids and Heat Exchangers. Other solutions include Pipeline Leak & Theft Detection Systems, Nitrogen Generators, Anti-Surge Systems, Pig Launchers & Receivers, Early Production Facilities and Mobile Offshore Production Units." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="process-image" pageTitle="Flow Drives (Rotating Equipment)" pageDesc1="PE Energy is renowned for providing high-quality process equipment, skids, and modules that are required for production processes. These effective and efficient process solutions include Pumps & Skid Mounted Pump Systems, Compressors & Compressor Skids, Chemical Injection Skids, Fuel Gas Conditioning Skids and Heat Exchangers. Other solutions include Pipeline Leak & Theft Detection Systems, Nitrogen Generators, Anti-Surge Systems, Pig Launchers & Receivers, Early Production Facilities and Mobile Offshore Production Units." pageDesc2="As a technology-driven company, PE Energy leverages the depth of experience of our highly trained engineers and the vast capacity of our partner original equipment manufacturers and system integrators to proffer industry-leading innovative solutions to a wide spectrum of clientele in the process industries.PE Energy supplies complete engineering, procurement, construction, and packaging of large onshore and offshore process modules with extensive capabilities to offer process technologies and deliver turnkey process solutions for equipment and skids. Our value proposition involves lowering the total project cost by working together with the project owner, general contractor, or major EPC company from Concept to Commissioning." />
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <h1 className="text-center mt-5">What we offer</h1>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Mobile Offshore Production Units (MOPU)</h3>
                    <p className="mb-5">As a leading solution provider in the oil and gas industry in Nigeria, PE Energy has a huge partnership network around the world. This network includes globally reputable and vastly experienced technical partners who have garnered decades of experience providing Mobile Offshore Production Units on a rental or complete turnkey EPC basis to oil and gas producers around the world. <br></br><br></br>

                        As of today, there are several technical, economic and environmental reasons decision-makers opt for Mobile Offshore Production Units rather than permanent structures. Some of these reasons include stringent regulatory requirements for minimum environmental footprint, developing an early production project and well testing.<br></br><br></br>

                        Additionally, oil and gas exploration and production companies also choose to deploy mobile offshore production units because the project may have limited funding for expensive permanent structures, a small to mid-size oil and gas development project or a short-duration project.<br></br><br></br>

                        Whatever may be the strategic objectives for the development of your oil and gas field, PE Energy is always ready to work closely with you as a partner to help develop your field while deploying a cost-effective mobile offshore production unit.</p>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Chemical Injection Skids</h3>
                    <p className="mb-5">The exact amount of chemicals and reagents required at certain temperatures, pressures and flow rates in a process or system is provided by a Chemical Injection Skid. In addition to other system requirements for chemicals, they are also applied for protection of the plant process from corrosion, wax, foam, scales, and hydrates. With the help of chemical injection skids at specific volumes, Concentrated reagents are delivered. This is to ensure the maintenance of bulk concentrations in the main process line.<br></br><br></br>

                        The complete chemical injection skid includes various materials like a two 100% metering/dosing pumps, chemical storage tank, a calibration column, tubing, valves, and filtration. The chemical strength, pump rate, and dilution water flow rate are closely monitored with online instrumentation while controlling the critical process parameters through valves that receive signals from a programmable logic controller.<br></br><br></br>

                        As an industry-leading solution provider, we design and manufacture fully optimized and customized Chemical Injection Skids that plug right into the flow of production at your plant. Our systems are developed, packaged and tested to ensure operations over an array of liquid chemical-treatment applications.</p>
                </div>

            </div>
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Fuel Gas Conditioning Skids</h3>
                    <p className="mb-5">Overtime, the operation of rotating equipment has improved as a result of proper removal of moisture and solid particles from the gas prior to critical usages. With the aid of a gas conditioning skid, natural gas is pre-treated before injecting into an important process or a Gas Turbine. Fuel Gas Conditioning systems are often used in the process and treatment of wellhead gas before transmission and distribution. Across various pipeline applications, natural gas needs to be conditioned to meet various strict pipeline specifications for transmission of the treated gas to the market.<br></br><br></br>

                        At PE Energy, we provide fuel gas conditioning skids as a complete package that enhances fuel gas quality by eliminating condensate, filtering particulates, regulating pressure and controlling gas temperature. We employ various types of filter elements in the pressure vessels for the removal of the liquid droplets and solid contaminants from the gas.<br></br><br></br>

                        Our modular designed skid-mounted gas conditioning system with instrumentation and controls for local and remote operation, offers a range of standard and custom-designed systems and controls to meet the requirements of each application, including fine process heating, filtration, pressure regulating and coalescing filtration.</p>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Compressors and Compressor Skids</h3>
                    <p className="mb-5">At PE Energy, we offer world-class air and gas compression technologies and deliver as a project package to our EPC clients and end-users. With extensive technical capabilities and experience in engineering, packaging, and integration, our compression solutions offer minimum life cycle costs. In addition to the several solutions we offer for our air and gas compressor packages, our CNG package offering includes a wide range of applications of the capacity of 300SCMH to 3200 SCMH with suction pressure from 0.5 to 65bar pressure delivering up to 255 bar pressure. The standard CNG package contains prime over, gas recovery tank with blowdown system, compressor, PLC panels, process coolers, priority panels, fire & gas leak detection system, complete set of instruments, internal wiring, internal tubing, and safety valves.<br></br><br></br>

                        The packages are designed to meet international safety standards. The systems are built in line with international engineering standards of API, ISO, ASME, and OSHA.<br></br><br></br>
                        Design is our strength. Pulsation Study, Torsional Analysis, Piping & Skid stress analysis, Thermal Optimization, etc. are done using the renowned latest software. We apply 3D modelling to produce compact designs with a minimum footprint, space requirements, and adequate accessibility.</p>
                </div>

            </div>
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Pig Launchers and Receivers</h3>
                    <p className="mb-5">PE Energy designs and manufactures a wide range of Pig Launchers and Receivers to meet clients’ and industry specifications of ASME VIII, ASME B31.3, PD5500, and PD8010 at cost-effective prices and satisfactory delivery schedule.<br></br><br></br>

                        We manufacture Pig Launchers and Receivers for the following applications:<br></br><br></br>

                        Temporary and permanent service<br></br><br></br>
                        Single or multiple pigging operations<br></br><br></br>
                        Topside and subsea pigging operations<br></br><br></br>
                        Our Pig Launchers and Receivers which are designed to allow for the fitment of pig locks, pig stops, and pig signallers are subjected to body test, valve seat test, valve torque test, and load test of the pull head.<br></br><br></br>
  
                        PIPELINE LEAK AND THEFT DETECTION SYSTEMS<br></br><br></br>

                        Pipeline theft and vandalism often cause monetary loss of millions of dollars every year. Leakages from pipelines are safety breaches that cause environmental damage, explosions and loss of lives. In the event of an illegal tap or leak, the pipeline product may spill and if left undetected, it may cause tremendous harm to the nearby ecosystem. The damage to the environment and safety concerns may result in the reputational damage of the pipeline owner which can take years to recover from.<br></br><br></br>

                        It is now more important than ever for pipeline operators across all industries to use reliable and sensitive leak and theft detection in order to minimize product loss and limit the potential damages. Depending on whether it is an oil, gas, water, multiproduct or multiphase pipeline, PE Energy in collaboration with our technical partner – Atmos International will select and integrate different leak detection technologies to optimise sensitivity, detection speed, reliability and accuracy. Our leak and theft detection technologies provide the following benefits:<br></br><br></br>

                        Ultra-fast detection time<br></br><br></br>
                        Accurate leak location<br></br><br></br>
                        High sensitivity (exceeding flow meter performance)<br></br><br></br>
                        Extremely low false alarms</p><br></br><br></br>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Anti-Surge Systems</h3>
                    <p className="mb-5">PE Energy designs and manufactures anti-surge systems for critical protection against dangerous pressure spikes and pipeline surges. These surge pressures which are due to rapid change in pipeline pressure are caused by a change of flow rate in the pipeline. Pressure surges travel at sonic velocities through the pipeline at speeds that can cause severe infrastructure damage, loss of product, as well as significant harm to the environment and personnel.<br></br><br></br>

                        PE Energy offers its safety-conscious clients best-in-class, optimally designed anti-surge systems that ensure that in normal conditions, surge relief valves are closed, but when a surge event disturbs a normal flow, the fluid pressure increases to the set point. This automatically and immediately opens the relief valve, relieving enough volume through a relief line to a surge tank or alternative sink to eliminate the surge. As the surge is dissipated, the valve closes again without slamming shut. The unit is again ready to act in case of any subsequent surge.<br></br><br></br>

                        The PE Energy anti-surge system also provides the fundamental requirements of surge relief systems which include the need for fast-acting, high capacity valves, capable of opening very quickly to remove surge pressures from the line, and then return to the normal state quickly but without causing additional pressure surge during the closure. PE Energy can provide properly specified and installed skid-integrated anti-surge systems that prevent accidents, reduce maintenance, and extend the equipment’s operating life.<br></br><br></br>

                        PE Energy can design and manufacture anti-surge systems with single or redundant parallel surge relief valves, inlet and outlet manifolds sized to minimize the pressure loss, as well as nitrogen systems. We can also ensure the anti-surge system comprises all the necessary devices and instrumentation, including manual valves, pressure and temperature indicators, and transmitters, a nitrogen control system, and, at times, flow meters that are full bore in-line ultrasonic to determine the relief quantity and rate.</p>
                </div>

            </div>
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Filters and Seals</h3>
                    <p className="mb-5">Filtration solutions provide better performance over the upstream, midstream, and downstream business units and also reduce the operating costs and improved process efficiency. Effective and reliable filtration is extremely important through the production and transportation of fluids, as failure to remove contaminants from critical processes results in decreased process efficiency, increased operating costs and heightened risk of damaging sensitive equipment downstream.<br></br><br></br>

                        Implementing an optimized filtration strategy across the process is key to reducing operating costs, minimizing downtime and improving overall business performance. PE Energy provides filtration solutions for all aspects of the process industry. These filters improve contaminant removal, reduce the frequency of maintenance activities, meet end-product quality specifications and lower operating costs for a streamlined and optimized processing operation.<br></br><br></br>

                        In addition, sealing applications need specialized and high-quality products as they demand extreme temperatures, very high pressures, and intense chemical aggression. Selecting the right sealing components and materials is very crucial as the wrong seal can affect the safety of the operator, reduce equipment reliability and potentially increase downtime. In support of the industry’s demand for high-quality seals, PE Energy offers a wide range of standard products that are designed to cope with high pressure and corrosive environments.<br></br><br></br> Our seals are used for applications such as actuators, pumps, compressors, turbines, valves, downhole tools, wellhead, pipework and many more.</p>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Dryers and Nitrogen Generators</h3>
                    <p className="mb-5">Nitrogen gas is used for a wide range of industrial applications including preventing fire and explosions in process plants. PE Energy offers the optimal solution with a wide range of cost-effective nitrogen generation systems from compressed air, that enable users to produce their total demand for nitrogen gas on their premises and under their control. The system can be installed simply with a compressor house or production area with standard piping without any special requirements. By the production of nitrogen gas on site and at low pressure, our solution eliminates the safety hazards usually associated with high-pressure cylinder gas and potential manual handling concerns.<br></br><br></br>

                        The PE Energy solution for nitrogen production involves compressors and pre-treatment packages such as adsorption dryers and coalescing filter to guarantee the highest quality air supply for the nitrogen generators. The design and control features employed by PE Energy help maximize gas output and reduce air consumption to achieve high levels of efficiency. Our modular concept offers greater flexibility that can be configured to suit installations as and when nitrogen demand rises.</p>
                </div>

            </div>
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Early Production Facilities (EPF)</h3>
                    <p className="mb-5">In the global Oil & Gas industry, an early production facility is considered a viable option for reducing the initial huge capital investment associated with exploration and production activities.<br></br><br></br>

                        PE Energy, in collaboration with our technical partners, provides the engineering, procurement, construction, and commissioning of early production facilities that meet stringent standards and short project lead time requirements. Our early production facility can be designed and installed on oil fields with light, heavy, sweet, or sour crude oil.</p>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Pumps and Skid Mounted Pump Systems</h3>
                    <p className="mb-5">PE Energy is a major supplier of a wide variety of pumps for numerous applications. These pumps are applied in the oil and gas, water, wastewater, food & beverage and several other non-oil and gas applications.<br></br><br></br>

                        Typically, a skid-mounted pump package consists of a pump, control panel and the pump driver which could either be mechanical i.e. internal combustion engine or electrical i.e. electric motor. These equipment are assembled on a base that forms the skid. PE Energy can supply single dynamic and positive displacement pumps as well as to conduct the system integration of a complete pumping unit.</p>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default Process;
