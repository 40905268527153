import React from "react";
import { Helmet } from "react-helmet";
import "./WaterProcessPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";

function WaterProcess() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Water & Process Technologies</title>
                <meta name="description" content="PE Energy in collaboration with its technical partner who is one of the world’s renowned leaders in water and process treatment has several areas of expertise including water technologies and solutions which entails engineered solutions for water and wastewater management." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="water-image" pageTitle="Water &amp; Process Technologies" pageDesc1="" pageDesc2="" />
            <div className="col-12 col-md-10 mx-auto mt-5">
                <p className="standard-text">

                    PE Energy in collaboration with its technical partner who is one of the world's renowned leaders in water and process treatment  has several areas of expertise including water technologies and solutions which entails engineered solutions for water and wastewater management.<br></br><br></br>

                    It also produces world class chemical products and oilfield chemicals, equipment and technical services for the upstream, midstream and downstream oil and gas industry.<br></br><br></br>

                    Our water and process treatment solution covers the non-oil and gas sectors including public and municipality water treatment solutions.<br></br><br></br>

                    We have experienced application specialists, production chemists and engineers who apply customer-centric approach and customer satisfaction as focus. We have a state-of-the-art laboratory and chemical blending plant domiciled in our centre of excellence.<br></br><br></br>

                    <span className="color-brand-blue">Our capabilities are as follows:</span> <br></br><br></br>

                    <li className="mb-2">Our capabilities cover the focus areas for our products and solutions which includes: Water, Energy and other industries</li>
                    <li className="mb-2">Asset protection and production enhancement solutions for oil and gas exploration and production which involves chemical treatment solutions, desalination chemicals and equipment, hydraulic fracturing solutions and laboratory facilities</li>
                    <li className="mb-2">Technical support and laboratories including specialized laboratory services, programme development and technical field support for the range of technologies supplied</li>
                    <li className="mb-2">Engineered solutions for freestanding plants, containerized plants, medium-sized plants, custom-engineered plants with a comprehensive solution package for brackish water, seawater desalination, reverse osmosis and wastewater re-use applications</li>
                    <li className="mb-2">Manufacturing of various specialty chemicals and water treatment products</li>
                    <li className="mb-2">Safe chemical handling systems which includes a unique, safe advanced chemical handling system that utilizes containers of various sizes</li>
                    <li className="mb-2">Managing, operating and maintaining program implementation in water treatment plants</li><br></br><br></br>
                    <p className="color-brand-blue">Focus on Water</p>

                    Our vast array of water management technologies includes:<br></br><br></br>
                    <li className="mb-2">Cooling water treatment</li>
                    <li className="mb-2">Membrane treatment</li>
                    <li className="mb-2">Boiler system treatment</li>
                    <li className="mb-2">Industrial effluent water treatment</li>
                    <li className="mb-2">Public water treatment</li>
                    <li className="mb-2">Wastewater chemical treatment</li>
                    <li className="mb-2">Process water treatment</li>
                    <li className="mb-2">Package plants and standard equipment</li>
                    <li className="mb-2">Custom engineered solutions</li><br></br><br></br>

                    <p className="color-brand-blue">The product applications include:</p>

                    <li className="mb-2">Feedwater pre-treatment, Corrosion control, Scale control, Microbial control, Fouling control, PH control, Foam control, Side-stream filtration and desalination, Cleaning and passivation, Closed-loop system treatment, Once-through system treatment, Open-recirculating system treatment, Cycle optimization, Blowdown treatment and recovery, Heavy duty diesel engine coolants, Real-time monitoring and control. </li>

                    <p className="color-brand-blue">Focus on Energy</p>
                    Provision of customized water and process chemical treatment technologies as well as equipment solutions to the energy industry which comprises of:

                    <li className="mb-2">Upstream oil and gas exploration and production industry</li>
                    <li className="mb-2">Crude oil refineries, Petrochemical plants and Finished fuel distribution companies</li>
                    <li className="mb-2">Synthetic fuel production facilities</li>
                    <li className="mb-2">Oil and gas treatment equipment</li>
                    <li className="mb-2">Power generation industry</li>
                    <li className="mb-2">Coal mining and processing industry</li>
                    <li className="mb-2">Renewable fuels industry</li><br></br><br></br>

                    <p className="color-brand-blue">The product applications include:</p>

                    <li className="mb-2">Emulsion breakers, Water clarifiers, Corrosion control, Scale control, Oxygen and hydrogen sulphide scavengers, Biocides, Foam control, Paraffin and Asphaltene inhibitors, Cleaners/Hydrates inhibitors, Reverse osmosis membrane treatments, Drilling fluid polymers, Lost circulation materials, Drag reducers, Pour point depressants, Acid treatments.</li>
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default WaterProcess;
