import React from "react";
import { Helmet } from "react-helmet";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import DefaultButton from "../../components/DefaultButtonComponent/DefaultButton";
import landing1 from '../../images/oil-gas.mov';
import landing2 from '../../images/power.mov';
import landing3 from '../../images/supply.mov';
import shell from '../../images/key customers/shell.jpg';
import daewoo from '../../images/key customers/daewoo.jpg';
import nnpc from '../../images/key customers/nnpc.jpg';
import chevron from '../../images/key customers/chevron.png';
import eni from '../../images/key customers/eni.png';
import exxon from '../../images/key customers/exxon.png';
import nlng from '../../images/key customers/nlng.png';
import saipem from '../../images/key customers/saipem.png';
import total from '../../images/key customers/total.png';
import epiimtce from '../../images/epiimtce_logo_white.png';
import epiimtce_transparent from '../../images/epiimtce.png';
import epiimtce_white from '../../images/epiimtce_white.png';
import vision from '../../images/vision.png';
import mission from '../../images/mission.png';
import strength1 from '../../images/strengths/1.png';
import strength2 from '../../images/strengths/2.png';
import strength3 from '../../images/strengths/3.png';
import strength4 from '../../images/strengths/4.png';
import strength5 from '../../images/strengths/5.png';
import strength6 from '../../images/strengths/6.png';
// import nies from '../../images/nies.png';
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// function LandingPage() {
//   const location = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     // document.title = "Home - PE Energy Limited"
//   }, [location]);
//   return (
function LandingPage() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // document.title = "Home - PE Energy Limited"

    // Display popup after 5 seconds
    const timer = setTimeout(() => {
      setOpen(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [location]);

  const [open, setOpen] = useState(false);

  return (
    <div className="landing-whole">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PE Energy - Your EPiiMtce Partner</title>
        <meta name="description" content="We specialize in delivering world-class Automation & Control, Valves & Actuation, Flow Measurement Solutions, Flow Measurement Solutionsower Solutions, Supply Chain & Project Management Solutions across our various digital platforms." />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      
      <NavBar />
      <div className="col-12 px-0 landing-jumbo row mx-0 px-o">
        {/* <div className="col-12 col-md-4 jumbo-text">
          <h1>Connecting Farmers to Investors</h1>
          <Link to="/auth/signup">
            <DefaultButton btnClass="btn-custom-green" buttonLabel="Get Started" />  
          </Link>
        </div>
        <div className="col-12 col-md-6 jumbo-fruits ml-auto">

        </div> */}
        <div id="carouselExampleIndicators" class="carousel slide px-0" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              {/* <img className="landing-images" src={landing1} alt="Landing Carousel Image" /> */}
              <video className="landing-images" autoPlay muted loop playsInline>
                <source src={landing1} alt="Landing Carousel Image" type="video/mp4"></source>
              </video>
              <div className="carousel-text">
                <h1 className="carousel-header">Your <img className="epiimtce-image-landing" src={epiimtce_white} alt="EPiiMtce" /> Partner</h1>
                {/* <span className="">PE Energy Limited is a leading provider of Engineering, Procurement, Integration, Installation and Maintenance (EPiiMtce) services in West Africa, specializing in Digitalization, Engineering, Procurement, Integration, Installation, and Maintenance across all her growth engines - Valves & Actuation, Flow Measurement Solutions, Flow Drives (Rotating Equipment), Exploration and Production, Automation & Controls. </span> */}
                <br></br>
                <Link to="/about" className="nav-link" href="#">
                  <button className="btn brand-btn-big mt-2">Learn More</button>
                </Link>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="10000">
              {/* <img className="landing-images" src={landing2} alt="Landing Carousel Image" /> */}
              <video className="landing-images" autoPlay muted loop playsInline>
                <source src={landing2} alt="Landing Carousel Image" type="video/mp4"></source>
              </video>
              <div className="carousel-text">
                <h1 className="carousel-header">Engineered Solutions for demanding and critical applications</h1>
                {/* <span className="">Through its growing reputation for trustworthy, transparent and timely execution of any project it undertakes, PE Energy has come to be the point of reference for international businesses who wish to enter the Western African market.</span> */}
                <br></br>
                <Link to="/about" className="nav-link" href="#">
                  <button className="btn brand-btn-big mt-2">Learn More</button>
                </Link>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="10000">
              {/* <img className="landing-images" src={landing3} alt="Landing Carousel Image" /> */}
              <video className="landing-images" autoPlay muted loop playsInline>
                <source src={landing3} alt="Landing Carousel Image" type="video/mp4"></source>
              </video>
              <div className="carousel-text">
                <h1 className="carousel-header">Robust Supply Chain Management Provider</h1>
                {/* <span className="">Through our strategic alliances with world-class manufacturers and suppliers, we deliver time and cost-effective supply chain management solutions and we have a track record of successfully handling the most complex demands.</span> */}
                <br></br>
                <Link to="/about" className="nav-link" href="#">
                  <button className="btn brand-btn-big mt-2">Learn More</button>
                </Link>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="white-con col-12 col-lg-10 col-md-11 mx-auto">
        <div className="col-12 text-center pt-5">
          <h1 className="section-title">Our <span classname="">Capabilities</span> and <span classname="">Growth Engines</span></h1>
        </div>
        <Splide
          options={{
            rewind: true,
            gap: '1rem',
            type: 'loop',
            perPage: 3,
            breakpoints: {
              1200: {
                perPage: 2,
              },
              768: {
                perPage: 1,
              },
            },
            perMove: 1,
            autoplay: true,
            interval: 5000
          }}
          aria-label="My Favorite Images" X
        >
          <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-epiimtce col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4">Engineering, Procurement, <b className="orange-text">Integration</b>, <b className="orange-text">Installation</b> and Maintenance</h1>
                <span className="growth-engine-title my-2">Our unique propositions lie on our EPiiMtce Model; Engineering, Procurement, Integration, Installation and Maintenance which drive our growth.</span> <br></br>

                <Link to="/epiimtce" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide>
          {/* <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-automation col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4 pt-3">Automation (E&I)	&amp; Control</h1>
                <span className="growth-engine-title my-2">We have vast experience and expertise in automation and control solutions for various Industrial Automation Requirements &amp; Projects.</span> <br></br>

                <Link to="/automation" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide> */}
          <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-process col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4 pt-3">Flow Drives (Rotating Equipment)</h1>
                <span className="growth-engine-title my-2">We provide our clients with reliable and cost-effective solutions for mechanical Systems and custom-design solutions for fluid management systems.</span> <br></br>

                <Link to="/process" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-measurement col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4 pt-3">Flow Measurement Solutions</h1>
                <span className="growth-engine-title my-2">We are your trusted partner in providing smart, reliable and accurate Metering and Analytical Solutions. Our expertise and solutions</span> <br></br>

                <Link to="/measurement" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-valves col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4 pt-3">Flow Control (Valves 	&amp; Actuation)</h1>
                <span className="growth-engine-title my-2">Meeting your needs and requirements becomes easier because we have
                  robust and complementary user-approved Valve &amp; Actutator brands</span> <br></br>
                <Link to="/valves" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide>
          {/* <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-exploration col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4 pt-3">Exploration &amp; Production</h1>
                <span className="growth-engine-title my-2">Meeting your needs and requirements becomes easier because we have
                  robust and complementary user-approved Valve &amp; Actutator brands</span> <br></br>

                <Link to="/exploration" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-digital col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4 pt-3">Digital Solutions</h1>
                <span className="growth-engine-title my-2">We help businesses digitalize their manufacturing, supply chain and financial workflows backed by strategic and operational maintenance support services.</span> <br></br>

                <Link to="/digital-solutions" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide> */}
          <SplideSlide>
            <div className="col-12 col-md mx-2 growth-engines-individual">
              <div className="growth-engines-icon growth-engines-supply col-12 px-0 text-center">

              </div>
              <div className="growth-engines-text col-12 px-5 text-center">
                <h1 className="growth-engine-title my-4 pt-3">Supply Chain Management</h1>
                <span className="growth-engine-title my-2">We are trusted partners of leading OEMs. With our expertise and resources, we work seamlessly with our partners.</span> <br></br>

                <Link to="/supply" className="nav-link" href="#">
                  <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
                </Link>
              </div>
            </div>
          </SplideSlide>
          {/* <SplideSlide>
          <div className="col-12 col-md mx-2 growth-engines-individual">
            <div className="growth-engines-icon growth-engines-valves col-12 px-0 text-center">

            </div>
            <div className="growth-engines-text col-12 px-5 text-center">
              <h1 className="growth-engine-title my-4 pt-3">Sales & Services Solution</h1>
              <span className="growth-engine-title my-2">Valves & Actuation, Flow Measurement Solutions, Process and Power Solutions, Exploration and Production, Automation and Controls.</span> <br></br>
              <Link to="/summary" className="nav-link" href="#">
                <button className="btn brand-btn-outline mt-4 mb-5 p-2">Read More</button>
              </Link>
            </div>
          </div>
        </SplideSlide> */}

        </Splide>
      </div>
      <div className="col-12 mx-auto mx-0 py-5 mt-5 vision-mission">
        <div className="col-12 col-md-9 row mx-0 mx-auto">
          <div className="col-12 col-md vision-mission-con row mx-auto text-center">
            <h5 className="mb-2">Our Vision <img className="ms-3" src={vision} alt="vision" /></h5>
            <p className="mt-3">Our Vision is to be Africa's leading energy servicing company.</p>
          </div>
          <div className="col-12 col-md vision-mission-con row mx-auto text-center">
            <h5 className="mb-2">Our Mission <img className="ms-3" src={mission} alt="mission" /></h5>
            <p className="mt-3">Our Mission is to ensure sustained growth, profitability and customer confidence, by continually advancing the performance of our operations and the quality of our service delivery.</p>
          </div>
        </div>
      </div>
      <div className="factors col-12 col-md-12 row mx-0 mx-auto row">
        <div className="factors-con col-12 col-md-10 row mx-auto mx-0">
          <div className="col-12 col-lg factors-text px-5 mb-5">
            <h1 className="section-title my-5">Why us? Our <span classname="">Strenghts.</span></h1>
            <div className="strengths-con">
              <img className="me-3 strength-icons" src={strength1} alt="strength1" />
              <p className="col-11">
                Highly Experienced team with expert level experience in <img className="epiimtce-logo margin-top-minus-8" src={epiimtce_white} alt="epiimtce" />.​</p>
            </div><br></br>
            <div className="strengths-con">
              <img className="me-3 strength-icons" src={strength2} alt="strength2" />
              <p className="col-11">Unassailable commitment to Corporate Governance and Compliance with all regulatory guidelines of the Oil & Gas Industry​</p>
            </div><br></br>
            <div className="strengths-con">
              <img className="me-3 strength-icons" src={strength3} alt="strength3" />
              <p className="col-11">
                Competent team to engineer customized solutions with leading Automation & Digital technologies.
              </p>
            </div><br></br>
            <div className="strengths-con">
              <img className="me-3 strength-icons" src={strength4} alt="strength4" />
              <p className="col-11">Measurement equipment such as Meters, Process Equipment such as Pumps, Valves, Compressors, turnkey process solutions, PLCs , DCS, IIOT, MES , SIS (Safety), Industrial Communication Networks Integration etc.
              </p>
            </div><br></br>
            <div className="strengths-con">
              <img className="me-3 strength-icons" src={strength5} alt="strength5" />
              <p className="col-11">Engineering & Design, Procurement, Integration, Installation & Maintenance including Application Software & Digitization capabilities for all major technology solution providers and platforms.
              </p>
            </div><br></br>
            <div className="strengths-con">
              <img className="me-3 strength-icons" src={strength6} alt="strength6" />
              <p className="col-11">Turnkey Project execution.
              </p>
            </div><br></br>
          </div>
          <div className="col-12 col-lg px-0">
            <div className="col-12 px-0 bubble-image mt-5">

            </div>
            {/* <div className="col-12 text-center as-far-as-text mb-5">
              <h1 className="section-title mt-5">As far as the <span classname="">eyes</span> can see.</h1>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="specialise-text col-11 col-md-6 mx-auto py-5">
        <h1>We specialize in delivering world-class Automation &amp; Control, Valves &amp; Actuation, Flow Measurement Solutions, Process &amp; Power Solutions, Exploration &amp; Production, Supply Chain &amp; Project Management Solutions across our various digital platforms.</h1>
      </div> */}
      <div className="col-12 px-0 market-focus-whole">
        <div className="col-12 text-center">
          <h1 className="section-title mt-5">Our Market <span classname="">Focus</span></h1>
        </div>
        <Splide
          options={{
            rewind: true,
            gap: '1rem',
            type: 'loop',
            perPage: 4,
            breakpoints: {
              768: {
                perPage: 1,
                drag: true,
                pagination: true
              },
            },
            perMove: 1,
            autoplay: true,
            interval: 5000,
            arrows: false,
            drag: true,
            pagination: true,
          }}
          aria-label="My Favorite Images" X
        >
          {/* <SplideSlide>
          <Link to="/market-focus" className="nav-link" href="#">
            <div className="col-12 col-lg mx-2 market-focus-individual">
              <div className="market-focus-icon border market-focus-oil col-12 px-0 text-center">
              </div>
              <div className="market-focus-text col-12 px-5 text-center">
                <h1 className="market-focus-title">Oil &amp; Gas</h1>
              </div>
            </div>
          </Link>
        </SplideSlide> */}
          <SplideSlide>
            <Link to="/market-focus" className="nav-link" href="#">
              <div className="col-12 col-lg mx-2 market-focus-individual">
                <div className="market-focus-icon border market-focus-upstream col-12 px-0 text-center">

                </div>
                <div className="market-focus-text col-12 px-5 text-center">
                  <h1 className="market-focus-title">Upstream</h1>
                </div>
              </div>
            </Link>
          </SplideSlide>
          <SplideSlide>
            <Link to="/market-focus" className="nav-link" href="#">
              <div className="col-12 col-lg mx-2 market-focus-individual">
                <div className="market-focus-icon border market-focus-midstream col-12 px-0 text-center">

                </div>
                <div className="market-focus-text col-12 px-5 text-center">
                  <h1 className="market-focus-title">Midstream</h1>
                </div>
              </div>
            </Link>
          </SplideSlide>
          <SplideSlide>
            <Link to="/market-focus" className="nav-link" href="#">
              <div className="col-12 col-lg mx-2 market-focus-individual">
                <div className="market-focus-icon border market-focus-downstream col-12 px-0 text-center">

                </div>
                <div className="market-focus-text col-12 px-5 text-center">
                  <h1 className="market-focus-title">Downstream</h1>
                </div>
              </div>
            </Link>
          </SplideSlide>
          <SplideSlide>
            <Link to="/market-focus" className="nav-link" href="#">
              <div className="col-12 col-lg mx-2 market-focus-individual">
                <div className="market-focus-icon border market-focus-power col-12 px-0 text-center">

                </div>
                <div className="market-focus-text col-12 px-5 text-center">
                  <h1 className="market-focus-title">Power</h1>
                </div>
              </div>
            </Link>
          </SplideSlide>
          {/* <SplideSlide>
          <Link to="/market-focus" className="nav-link" href="#">
            <div className="col-12 col-lg mx-2 market-focus-individual">
              <div className="market-focus-icon border market-focus-digital col-12 px-0 text-center">

              </div>
              <div className="market-focus-text col-12 px-5 text-center">
                <h1 className="market-focus-title">Digitalization</h1>
              </div>
            </div>
          </Link>
        </SplideSlide> */}
          <SplideSlide>
            <Link to="/market-focus" className="nav-link" href="#">
              <div className="col-12 col-lg mx-2 market-focus-individual">
                <div className="market-focus-icon border market-focus-food col-12 px-0 text-center">
                  {/* <img className="mx-auto d-block mt-4" src={valvesIcon} alt="valvesIcon"/> */}
                  {/* <img className="mx-auto d-block mt-4" src={valvePhoto} alt="valvesPhoto"/> */}
                </div>
                <div className="market-focus-text col-12 px-5 text-center">
                  <h1 className="market-focus-title">Process Industries</h1>
                </div>
              </div>
            </Link>
          </SplideSlide>
        </Splide>
      </div>
      <div className="welcome-coe col-12 col-md-10 row mx-0 mx-auto mt-5 row">
        <div className="col-12 col-lg-7 coe-image mb-5">

        </div>
        <div className="col-12 col-lg-5 coe-text px-5">
          <h1 className="my-5">PE Energy Ltd expands its solutions to <img className="epiimtce-logo margin-top-minus-5 epiimtce-height-big" src={epiimtce} alt="epiimtce" /></h1>
          <p className="col-11">We are happy to announce that our company has been restructured and reengineered to include <img className="epiimtce-logo margin-top-minus-8" src={epiimtce} alt="epiimtce" /> – Engineering Design, Procurement/Supply Chain, <b className="orange-text no-bold">Integration</b>, <b className="orange-text no-bold">Installation</b> and Maintenance of process facilities namely:
            <br></br> <br></br>
            flow station, Production- Platforms, Topside/Jackets, FPSO, Pipeline and Facilities, Power Plant, Gas Plant, Marine Tools/Vessels. Our <img className="epiimtce-logo margin-top-minus-8" src={epiimtce} alt="epiimtce" /> cuts across many industries - Oil & Gas (Onshore & Offshore), Power, Water & Process Technologies, Process Industries, and other industries.</p>
          <b>We have re-positioned to serve you better and wider.</b>

          {/* <button className="btn brand-btn mt-3">Learn More</button> */}
        </div>
      </div>
      <div className="col-12 text-center">
        <h1 className="section-title my-5">Our <span classname="">Key</span> Customers</h1>
      </div>
      <div className="key-customers col-12 col-md-7 row mx-auto">
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={shell} alt="shell" />
        </div>
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={daewoo} alt="daewoo" />
        </div>
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={total} alt="total" />
        </div>
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={nlng} alt="nlng" />
        </div>
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={saipem} alt="saipem" />
        </div>
        <br></br>
      </div>
      <div className="key-customers col-7 row mx-auto">
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={chevron} alt="chevron" />
        </div>
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={exxon} alt="exxon" />
        </div>
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={nnpc} alt="nnpc" />
        </div>
        <div className="customer-logo col mb-5">
          <img className="mx-auto d-block mt-4" src={eni} alt="eni" />
        </div>
      </div>
      <Footer />
      {/* Popup component */}
      {/* <Popup open={open} closeOnDocumentClick={true}>
        <div className="popup-holder">
        <img className="nies-images" src={nies} alt="NIES Advert" />
        </div>
      </Popup> */}
    </div>
  );
}

export default LandingPage;
