import React from "react";
import { Helmet } from "react-helmet";
import "./SummaryPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";
import epiimtce from '../../images/epiimtce_logo_white.png';
import epiimtce_transparent from '../../images/epiimtce.png';
import epiimtce_white from '../../images/epiimtce_white.png';

function Summary() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div className="summary-whole">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Our EPiiMtce Model</title>
                <meta name="description" content="As an Engineering, Procurement, Integration, Installation, and Maintenance company, the purpose of the Organization is to provide quality services in Automation & Control, Valves & Actuation, Process Solutions, Flow Measurement Solutions, Electrical & Instrumentation Solutions, and Supply Chain Services to all our Customers.e" />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="epiimtce-image" pageTitle={["Our ", <img className="epiimtce-image-landing" src={epiimtce_white} alt="EPiiMtce" />, " Model"]} pageDesc1="" pageDesc2=""/>
            <div className="bubble-chart-con col-12 px-0">
                <div className="col-12 col-lg-11 col-xl-9 row mx-auto mx-0">
                    <div className="bubble-chart col-12 col-lg-6 mt-5">

                    </div>
                    <div className="col-12 col-lg-6 blue-background-words">
                        <p className="col-12 col-lg-10 mx-auto p-5">
                            <h1 className="mb-4">Our <img className="epiimtce-image-landing" src={epiimtce_white} alt="EPiiMtce" /> Model</h1>
                            Our unique propositions lie on our <img className="epiimtce-logo margin-top-minus-8" src={epiimtce_white} alt="epiimtce" /> Model; Engineering, Procurement, <span className="orange-text">Integration</span>, <span className="orange-text">Installation</span> and Maintenance which drive our growth. We position our business for longevity by offering a broader alternative solution to compete with major EPC companies in the country.
                            <br></br><br></br>
                            The essence of our value-added services goes beyond those provided by classical engineering, fabrication and construction companies domiciled in the country. In fact, the <img className="epiimtce-logo margin-top-minus-8" src={epiimtce_white} alt="epiimtce" /> model incentivize our customers to rely on our expertise to embark on life cycle projects from inception to long term turnaround maintenance.
                        </p>
                    </div>
                </div>
                {/* <video src={epiimtceVideo} autoplay="true" loop="loop" muted="muted" preload="auto" /> */}
            </div>
            {/* <div className="growth-engine col-12 automation-con row mx-0 mx-auto">
                <div className="col-12 col-md-7 mx-auto growth-engine-text">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Automation &amp; Control</h1>
                        <h6 className="col-12 col-md-10 mt-3">As an Engineering, Procurement, Integration, Installation, and Maintenance company, the Purpose of the Organization is to provide quality services in Automation and Controls, Valves & Actuation, Process Solutions, Flow Measurement Solutions, Electrical & Instrumentation Solutions, and Supply Chain Management to all our customers with domain solutions in Water & Process Treatment, Wastewater...</h6>

                        <Link to="/automation" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div> */}
            <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-2 growth-engine-image growth-engine-image-valve">

                </div>
                <div className="col-12 col-md order-1 order-md-1 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Flow Control (Valves & Actuation)</h1>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3">We have an extensive portfolio of the user-approved valve and actuator brands, thus allowing us to meet the various needs of process industries. Our various team members are trained directly by OEMs. Thus, making us a trusted name in Nigeria for valves and actuation solutions. With our valve & automation...</h6>
                        <Link to="/valves" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-1 growth-engine-image growth-engine-image-measurement">

                </div>
                <div className="col-12 col-md order-1 order-md-2 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Flow Measurement Solutions</h1>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3">Our customers rely on us as their trusted partner in providing smart, reliable, accurate and cost-effective Metering and Analytical Solutions. Our expertise and solutions in metering and analytical systems cover from Wellhead Multiphase & Wet Gas Metering, Water Cut Metering, Custody Transfer Metering, LACT Systems to Flare Metering as well...</h6>
                        <Link to="/measurement" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-2 growth-engine-image growth-engine-image-process">

                </div>
                <div className="col-12 col-md order-1 order-md-1 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Flow Drives (Rotating Equipment)</h1>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3">We provide our clients with reliable and cost-effective solutions for mechanical Systems and custom-design solutions for fluid management systems. Our core strength is our experienced and skilled engineers. Our partner OEM's have a wide range of cost-effective solutions for various Pipeline related requirements that include leakages and theft detection.</h6>
                        <Link to="/process" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-1 growth-engine-image growth-engine-image-supply">

                </div>
                <div className="col-12 col-md order-1 order-md-2 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Supply Chain &amp; Project Management</h1>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3">Our customers rely on us as their trusted partner in providing smart, reliable, accurate and cost-effective Metering and Analytical Solutions. Our expertise and solutions in metering and analytical systems cover from Wellhead Multiphase & Wet Gas Metering, Water Cut Metering, Custody Transfer Metering, LACT Systems to Flare Metering as well...</h6>
                        <Link to="/supply" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-2 growth-engine-image growth-engine-image-automation">

                </div>
                <div className="col-12 col-md order-1 order-md-1 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Automation &amp; Control</h1>
                        <h6 className="col-12 col-md-10 mt-3">As an Engineering, Procurement, Integration, Installation, and Maintenance company, the Purpose of the Organization is to provide quality services in Automation and Controls, Valves & Actuation, Process Solutions, Flow Measurement Solutions, Electrical & Instrumentation Solutions, and Supply Chain Management to all our customers with domain solutions in Water & Process Treatment, Wastewater...</h6>

                        <Link to="/automation" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div> */}
            {/* <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-1 growth-engine-image growth-engine-image-exploration">

                </div>
                <div className="col-12 col-md order-1 order-md-2 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Exploration &amp; Production</h1>
                        
                        <h6 className="col-12 col-md-10 mt-3">We have extensive knowledge and experience in the Supply Chain and Project Management. We have experience in providing customized solution and supplying various types of equipment and machinery including Pipes, Fittings, Valves and Spares, Flanges, Rotary equipment's, Process equipment's, Measurements equipment's, Electrical/Instrumentation, Heavy Plant Machinery, Balance of Plant equipment's, Drilling...</h6>
                        <Link to="/exploration" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div> */}
            {/* <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-2 growth-engine-image growth-engine-image-digital">

                </div>
                <div className="col-12 col-md order-1 order-md-1 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Digital Solutions</h1>
                       
                        <h6 className="col-12 col-md-10 mt-3">We help businesses digitalize their manufacturing, supply chain and financial workflows backed by strategic and operational maintenance support services.</h6>
                        <Link to="/digital-solutions" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div> */}
            <div className="growth-engine col-12 col-md-11 col-lg-9 row mx-0 my-5 mx-auto">
                <div className="col-12 col-md order-2 order-md-2 growth-engine-image growth-engine-image-hydraulics">

                </div>
                <div className="col-12 col-md order-1 order-md-1 growth-engine-text pt-5">
                    <div className="col-12 ps-5">
                        <h1 className="mb-5">Hydraulics</h1>
                        {/* <h2 className="">Water &amp; Process Technologies</h2> */}
                        <h6 className="col-12 col-md-10 mt-3">Coming Soon.</h6>
                        {/* <Link to="/digital-solutions" className="nav-link" href="#">
                            <button className="btn btn-link mt-4">Learn More</button>
                        </Link> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Summary;
