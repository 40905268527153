import React from "react";
import { Helmet } from "react-helmet";
import "./ValvesPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";

function Valves() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Flow Control</title>
                <meta name="description" content="At PE Energy, we have the required expertise for providing the highest quality of Flow Control (Valves & Actuation) solutions.

We are the trusted partners of world-leading OEMs, which enables us to offer the best services without compromising on safety. Our final solution deliveries for varying flow control, are in line with international regulatory standards, hence bound to meet our client's expectations. We are doing this since the year 2009. Our team of professionals is trained by Original Equipment Manufacturers (OEM) thus ensuring that the product and services delivered are of the highest quality." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="valve-image" pageTitle="Flow Control (Valves &amp; Actuation)" pageDesc1="" pageDesc2="We are the trusted partners of world-leading OEMs, which enables us to offer the best services without compromising on safety. Our final solution deliveries for varying flow control, are in line with international regulatory standards, hence bound to meet our client's expectations. We are doing this since the year 2009." />
            <div className="col-12 col-md-10 mx-auto mt-5 valves-text">
                <h1 className="section-title mb-5 text-start ms-5">Need of <span classname="">Seamless</span> Execution</h1>
                <p className="col-12 col-md-10 mt-3 standard-text">At PE Energy, we have the required expertise for providing the highest quality of Flow Control (Valves & Actuation) solutions. <br></br><br></br> We are the trusted partners of world-leading OEMs, which enables us to offer the best services without compromising on safety. Our final solution deliveries for varying flow control, are in line with international regulatory standards, hence bound to meet our client's expectations. We are doing this since the year 2009. Our team of professionals is trained by Original Equipment Manufacturers (OEM) thus ensuring that the product and services delivered are of the highest quality.<br></br><br></br> We manage the full life cycle of any type of valve and actuator starting from the sale to installation & commissioning, maintenance, repairs, testing, calibration, training and recertification with fast delivery, thereby ensuring that there is negligible downtime. Valve Asset Management and Obsolescence Management is also a key service for us, consisting of us managing an entire asset to ensure continuous operations and integrity as well as identifying obsolete parts in complex machinery and replacing it with current technology parts to prevent losses and ensure ease of continuous operations.<br></br><br></br>

                    Our Valve Assembly & Automation Centre of Excellence contains a state-of-the-art test bench for workshop services as well as a mobile workshop. This can be used on-site for clinical services, consisting of lapping machines, Wheelabrator for blasting and corrosion effect mitigation, compressors and paint booth, etc. With activities ranging across refurbishment, testing, calibration, painting, actuation and re-certification of various valve types and field instruments as well as installation and commissioning. We guarantee the delivery of an optimum product, its perfect installation and functioning on-site and the issuing of recertification post checking of the various equipment in a unit.<br></br><br></br> Our solid reputation in the Sales and Maintenance of Valves & Actuators is for us the reward of decades of hard work, committed to quality.</p>
            </div>
            <div className="col-12 mx-auto mt-5">
                <div className="col-12 col-md-6 full-collapse">
                    <p className="py-3" data-bs-toggle="collapse" href="#butterflyvalves" role="button" aria-expanded="false" aria-controls="butterflyvalves">
                        <a class="ms-5">
                            Butterfly Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="butterflyvalves">
                            <div class="card card-body">
                                Butterfly valves are quarter-turn valves that function in manual, electric, hydraulic and pneumatic actuators. These are available in steel and plastic models and some of the models have a cartridge seat design that helps during the installation process.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#gatevalves" role="button" aria-expanded="false" aria-controls="gatevalves">
                        <a class="ms-5">
                            Gate Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="gatevalves">
                            <div class="card card-body">
                                Gate valves operate by lifting a barrier and allowing the fluid to flow. These valves operate in fully open or fully close services. The gate valves are operated by means of a threaded stem.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#globevalves" role="button" aria-expanded="false" aria-controls="globevalves">
                        <a class="ms-5">
                            Globe Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="globevalves">
                            <div class="card card-body">
                                Globe valves operate as linear motion valves and they are used to control the flow of gases, liquid, and steam. They consist of a movable plug or disc element and a stationary ring seat in a generally spherical body having a high flow capacity. Some models come with metal-to-metal seating for excellent flow control.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#tripleoffsetvalves" role="button" aria-expanded="false" aria-controls="tripleoffsetvalves">
                        <a class="ms-5">
                            Triple Offset Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="tripleoffsetvalves">
                            <div class="card card-body">
                                Triple offset valves are reliable, efficient, and durable. Offset valves have zero – leakage sealing capabilities, hence they provide the best performance to ensure reliable operation of your processes at top efficiency. The seat is designed to minimize wear and tear resulting in reduced maintenance costs and growth in the life span of the valve.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#chokevalves" role="button" aria-expanded="false" aria-controls="chokevalves">
                        <a class="ms-5">
                            Choke Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="chokevalves">
                            <div class="card card-body">
                                Choke Valves are useful across various oil and gas production wells as they control the flow of well-fluids. They are also widely used to kill pressure from the reservoir and regulate the pressure in flowlines. These valves are designed to handle erosive services.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#controlvalves" role="button" aria-expanded="false" aria-controls="controlvalves">
                        <a class="ms-5">
                            Control Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="controlvalves">
                            <div class="card card-body">
                                Control valves are used to control fluid flow. This is done by varying the size of the flow passage as directed by a signal from a controller. This helps the users to directly control the flow rate and also consequential control of other quantities like pressure, liquid level, and temperature.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#checkvalves" role="button" aria-expanded="false" aria-controls="checkvalves">
                        <a class="ms-5">
                            Check Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="checkvalves">
                            <div class="card card-body">
                                Check valves are commonly known as non-return valves. They normally allow the fluid to flow only in one direction. They are some of the few self-automated valves that do not require assistance to open and close and are widely used in commercial and industrial applications
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#ballvalves" role="button" aria-expanded="false" aria-controls="ballvalves">
                        <a class="ms-5">
                            Ball Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="ballvalves">
                            <div class="card card-body">
                                Ball Valves are quarter-turn valves which use a hollow, pivoting and perforated ball to manage the flow through it and are available in various actuation configurations. They are used in heating systems, water treatment, and gas or liquid flow control.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#safetyvalves" role="button" aria-expanded="false" aria-controls="safetyvalves">
                        <a class="ms-5">
                            Safety Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="safetyvalves">
                            <div class="card card-body">
                                Safety valves function as a fail-safe. The pressure relief valve is an ideal example of a safety valve. It automatically releases a substance, when the pressure or temperature exceeds the preset limits. These are widely used in high-pressure vessels like chemical plants, electric power and gas storage tanks.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#reliefvalves" role="button" aria-expanded="false" aria-controls="reliefvalves">
                        <a class="ms-5">
                            Relief Valves
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="reliefvalves">
                            <div class="card card-body">
                                Relief Valves or pressure relief valves are used to control or limit the pressure in a system. In the absence of these, pressure might build-up leading to process upset, equipment or instrument failure, or fire. Pressurized fluids flow from an auxiliary passage out of the system, to relieve the pressure.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#hippsvalves" role="button" aria-expanded="false" aria-controls="hippsvalves">
                        <a class="ms-5">
                            High-Integrity Pressure Protection System -“HIPPS”
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="hippsvalves">
                            <div class="card card-body">
                                The HIPPS is a system for instrumented safety. It has been designed and built by following standards set by IEC 61508 and 61511. The system shuts the source of over-pressure, within 2 seconds, thus making the whole system as reliable as the safety relief valve.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#valveinterlocks" role="button" aria-expanded="false" aria-controls="valveinterlocks">
                        <a class="ms-5">
                            Valve Interlocks
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="valveinterlocks">
                            <div class="card card-body">
                                A Valve Interlock is a trapped key lock assembly which locks the valve in one or two positions – open and/or closed – to prevent unauthorized operation of the valve as well as controlling the sequence of events of conducting different valve process activities.
                            </div>
                        </div>
                    </div>
                    <p className="py-3" data-bs-toggle="collapse" href="#psinterlocks" role="button" aria-expanded="false" aria-controls="psinterlocks">
                        <a class="ms-5">
                            Process &amp; Safety Interlocks
                        </a>
                    </p>
                    <div>
                        <div class="collapse multi-collapse" id="psinterlocks">
                            <div class="card card-body">
                                Process and Safety Interlocks prevent incorrect operation or possible damage to the process or equipment by enforcing and guaranteeing a pre-defined sequence of operation to eliminate human error.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Valves;
