import React from "react";
import { Helmet } from "react-helmet";
import "./MeasurementPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";

function Measurement() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Flow Measurement Solutions</title>
                <meta name="description" content="Trusted Partner in Integrated Metering Solutions (IMS) and Calibration Services throughout Nigeria

Our customers rely on us as their trusted partner in providing smart, reliable, accurate and cost-effective Metering and Analytical Solutions. Our expertise and solutions in metering and analytical systems cover from Wellhead Multiphase & Wet Gas Metering, Water Cut Metering, Custody Transfer Metering, LACT Systems to Flare Metering as well as online Gas Chromatography, Dew-point Analyzer and Auto-Sampling Applications." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="measurement-image" pageTitle="Flow Measurement Solutions"
                pageDesc1="Trusted Partner in Integrated Metering Solutions (IMS) and Calibration Services throughout Nigeria"
                pageDesc2="Our customers rely on us as their trusted partner in providing smart, reliable, accurate and cost-effective Metering and Analytical Solutions. 
            Our expertise and solutions in metering and analytical systems cover from Wellhead Multiphase & Wet Gas Metering, Water Cut Metering, Custody Transfer Metering, 
            LACT Systems to Flare Metering as well as online Gas Chromatography, Dew-point Analyzer and Auto-Sampling Applications." />
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <h1 className="text-center mt-5">We offer the following custom design solutions to meet regulatory and clients project requirements</h1>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Integrated Metering & LACT Skids</h3>
                    <p className="mb-5">Integrated Metering and LACT Skids including valves, electrical & instrumentation, and automation are at the heart of hydrocarbon accounting operations.
                        They require intelligent and robust design and fabrication to enhance operational and commercial efficiencies and benefits. <br></br><br></br>
                        PE Energy Limited with an adequate understanding of national and international standards is the leading Metering Skids Integrator in Nigeria,
                        employing a trained and qualified personnel that handle installation, commissioning, validation, auditing and reconciliation, so as to meet all your measurement requirements.</p>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Multiphase & Wet Gas Metering Solutions</h3>
                    <p className="mb-5">Well Testing is a critical operational requirement to predict the performance of Reservoirs and comprehensively plan well intervention programs within budget. <br></br><br></br>
                        Conventionally, surface well test separators are deployed to acquire well testing data for reservoir management, but this method has inherent adequate separation and measurement challenges with high safety risk and cost of operation.<br></br><br></br>
                        PE Energy Limited is championing the deployment of compact, stable, accurate and cost-effective Multiphase & Wet gas Metering Solutions replacing surface test separators for Reservoir Surveillance, Well Testing, Water Breakthrough, and Hydrate Monitoring applications.</p>
                </div>

            </div>
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Gas Analytical Solutions</h3>
                    <p className="mb-5">The importance of gas chromatographs and hydrocarbon dew pointing for the quality and quantity determination of gas cannot be over-emphasized.<br></br><br></br>
                        We understand the uniqueness of these solutions to gas fiscal metering systems and pay special attention in our design, assembly, and testing of gas analytical cabinets complying with stringent national, international, and client requirements. We also provide our clients with analyzer management software solutions that enable them to track the performance and maintenance requirements of gas chromatographs and dew point analyzers for optimum operational reliability.</p>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-5">
                    <h3 className="my-5">Auto-Sampling Solutions</h3>
                    <p className="mb-5">An important factor in petroleum measurement is representative sampling and quality analysis to ensure proper accounting for transactions and efficient product processing. <br></br><br></br>
                        Hence, Liquid Metering Systems usually work along with a Sampling System. We design and supply Manual, Fast Loop or Isokinetic Homogenous Mixing systems to ensure compliance with ISO 3171 and API MPMS 8.2 using state-of-the-art sampling instruments.</p>
                </div>

            </div>
            <div className="col-12 col-md-9 mx-auto mx-0 row card-offerings">
                <div className="col-12 col-md mt-5 mx-5 px-0">
                    <div className="offerings-as-card col-12 py-3 px-5">
                        <h3 className="my-5">Water Cut Metering Solutions</h3>
                        <p className="mb-5">Fiscal Allocation Measurement is a crucial metering application, requiring highly reliable instruments for production allocation. <br></br><br></br>
                            In most applications, operators of different production facilities deliver the multiphase fluid of crude oil and water after primary stabilization in a flow station through a common pipeline to an integrated Oil & Gas facility for further treatment to export-grade crude oil. <br></br><br></br>
                            Reconciliation and allocation of production to the different stakeholders or injectors is one challenge that the industry in Nigeria has been facing. To answer that, PE Energy Limited is leveraging its experience in metering solutions and has resolved the problem by deploying microwave-based technology water cut meters, with a proven accuracy of +/-1% higher than the industry standard.<br></br><br></br>
                        </p>
                    </div>
                    <div className="offerings-as-card col-12 py-3 px-5 mt-5">
                        <h3 className="my-5">High-quality Instrument valves, Manifolds and Monoflanges</h3>
                        <p className="mb-5">
                            Through our international OEMs, we are capable of providing complete Turnkey instrumentation solutions, focusing on the following:
                            <ul>
                                <li>Providing “One stop shopping solutions”, ready for installation, tagged, leak tested, fully documented and completely assembled</li>
                                <li>Reduce your Total Cost of Ownership</li>
                                <li>Minimizing your risk (costly failures) by making Flawless start up possible</li>
                                <li>Making it a perfect match of our manifolds & monoflanges with every well-known brand of Transmitters and wide range of instruments.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="offerings-as-card col-12 col-md mt-5 mx-5 px-0">
                    <div className="col-12 px-5">
                        <h3 className="my-5">Custody Transfer Solutions</h3>
                        <p className="mb-5">At PE Energy Limited, one of our key business focus areas is Flow Measurement. Custody transfer measuring point in the Oil & Gas Industry is a “cash register” requiring highly accurate and stable flow instrumentation. We provide long-term stable, highly repeatable, accurate and robust Coriolis Flow Meter for Allocation & Custody Transfer Metering Solutions.<br></br><br></br>
                            Even though the properties of liquid hydrocarbons, such as density and viscosity, vary greatly and thus can affect accuracy, Coriolis measuring technology from Endress+Hauser has proven to be the best current method for metering viscous fluids or fluids with entrained gas reliably.<br></br><br></br>

                            For this reason, Coriolis technology is steadily becoming the reference-technology for verifying installed flowmeters and is even used by metrology authorities as a transfer standard for on-site calibrations.<br></br><br></br>

                            The advantages of our Promass Coriolis Meter include:<br></br><br></br>

                            <ul>
                                <li>Minimal measurement uncertainty – highest repeatability and long-term stability.</li><br></br>
                                <li>Multifunctional – simultaneous measurement of mass, volume, density, and temperature.</li><br></br>
                                <li>Reliable measuring technology – insensitive to fluctuating process conditions, pipe vibrations and external pipe forces.</li><br></br>
                                <li>Maintenance-free – cost-effective operation.</li><br></br>
                                <li>Reliable – no blockages or measured value drift as is the case of mechanical meters.</li><br></br>
                                <li>Heartbeat Technology – traceable device verification during operation (TÜV-certified).</li><br></br>
                                <li>Versatile in use – Promass flowmeters are fully compatible with installed calibration references on site.</li>
                            </ul>
                        </p>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default Measurement;
