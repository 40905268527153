import React from "react";
import { Helmet } from "react-helmet";
import "./ExperiencePage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import gbaran_ubie from '../../images/Projects/gbaran-ubie.png'
import anyala from '../../images/Projects/anyala.jpg'
import train2_valve from '../../images/Projects/train2-valve.jpg'
import area_b from '../../images/Projects/area-b.jpg'
import atmos from '../../images/Projects/atmos.jpg'
import calibration from '../../images/Projects/calibration.png'
import chevron_tam from '../../images/Projects/chevron-tam.jpg'
import eafod from '../../images/Projects/eafod.jpg'
import egbin from '../../images/Projects/egbin.jpg'
import gas_turbine_job from '../../images/Projects/gas-turbine-job.jpg'
import hipps_logic from '../../images/Projects/hipps-logic.jpg'
import madu from '../../images/Projects/madu.png'
import nlng from '../../images/Projects/nlng.png'
import nlng_area_b from '../../images/Projects/nlng-area-b.jpg'
import obiafu from '../../images/Projects/obiafu.jpg'
import train3_vms from '../../images/Projects/train3-vms.jpg'
import train5 from '../../images/Projects/train5.jpg'
import { useEffect } from "react";
import { useLocation } from "react-router";

function Experience() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Experience</title>
                <meta name="description" content="An Engineering Procurement, Integration, Installation and Maintenance company" />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="Experience-image" pageTitle="Our Experience" pageDesc1="" pageDesc2="" />
            <div className="col-12 experience-whole">
                <div className="col-12 projects">
                    <div className="projects-con col-12 col-md-9 row mx-auto mx-o">
                        <div className="col-12 mt-5">
                            <h1 className="section-title">Our Recent <span classname="">Projects</span></h1>
                        </div>
                        {/* <div className="col-12 text-end projects-details">
                            <a target='blank' href="https://docs.google.com/presentation/d/1cxz0l7LYY0Y6TAbziRadl9HQ8GboAVrX/edit?usp=sharing&ouid=110014617300953921991&rtpof=true&sd=true"
                             className="btn brand-btn-big">See all projects and details >></a>
                        </div> */}
                        <Splide
                            options={{
                                rewind: true,
                                gap: '1rem',
                                type: 'loop',
                                perPage: 5,
                                breakpoints: {
                                    1200: {
                                        perPage: 3,
                                        perMove: 1,
                                        autoplay: true,
                                    },
                                    768: {
                                        perPage: 1,
                                        perMove: 1,
                                        autoplay: true,
                                    },

                                },
                                perMove: 0,
                                autoplay: false,
                                interval: 2000,
                                arrows: false
                            }}
                            aria-label="My Favorite Images" X
                        >
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#oneModal">
                                    <div className="splide-slide-icon splide-slide-project1 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">Gbaran Ubie PH3A Project</h1>
                                        <span className="mb-4">Valves testing and repair, Valve cage replacement</span> <br></br>
                                    </div>
                                    {/* <div className="splide-slide-text col-12 px-4 text-center pb-4">
                                        <h1 className="splide-slide-title mt-4 mb-2">Gbaran Ubie PH3A Project​</h1>
                                        <span className="growth-engine-title mt-2">Valves testing and repair, Valve cage replacement</span> <br></br>
                                    </div> */}
                                </div>

                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#twoModal">
                                    <div className="splide-slide-icon splide-slide-project2 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">Obiafu 41 Development Project</h1>
                                        <span className="mb-4">HIPPS Installation & Commissioning</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#threeModal">
                                    <div className="splide-slide-icon splide-slide-project3 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">Madu OML 85 Field</h1>
                                        <span className="mb-4">HIPPS Commission for Madu Production Platfrom</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#fourModal">
                                    <div className="splide-slide-icon splide-slide-project4 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">NLNG Train 4 valve maintenance service</h1>
                                        <span className="mb-4">Valve Overhaul, Accessories Change-out...</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#fiveModal">
                                    <div className="splide-slide-icon splide-slide-project5 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">Calibration Services</h1>
                                        <span className="mb-4">Calibration of PCV in Olero and Abiteye</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                        </Splide>
                        <Splide
                            options={{
                                rewind: true,
                                gap: '1rem',
                                type: 'loop',
                                perPage: 5,
                                breakpoints: {
                                    1200: {
                                        perPage: 3,
                                        perMove: 1,
                                        autoplay: true,
                                    },
                                    768: {
                                        perPage: 1,
                                        perMove: 1,
                                        autoplay: true,
                                    },

                                },
                                perMove: 1,
                                autoplay: false,
                                interval: 3500,
                                arrows: false
                            }}
                            aria-label="My Favorite Images" X
                        >
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#sixModal">
                                    <div className="splide-slide-icon splide-slide-project6 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">ANYALA & MADU (OML 83 & 85) Field Development Project</h1>
                                        <span className="mb-4">Supply & Commissioning of 3 units of HIPPS for Anyala & Madu Fields Development Project</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#sevenModal">
                                    <div className="splide-slide-icon splide-slide-project7 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">EAFOD Actuator Replacement and Calibration</h1>
                                        <span className="mb-4">Repair and Calibration of Choke Valve</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#eightModal">
                                    <div className="splide-slide-icon splide-slide-project8 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">ATMOS Portable Tightness Monitor commissioning</h1>
                                        <span className="mb-4">Commissioning of ATMOS Portable Tightness Monitor</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#nineModal">
                                    <div className="splide-slide-icon splide-slide-project9 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">Area B Emergent Shutdown (Valve Maintenance Services)</h1>
                                        <span className="mb-4">Accessories Change-out, Valve overhaul, Actuator Overhaul,
                                            Re-assembly & Testing as per VMS implementation
                                        </span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#tenModal">
                                    <div className="splide-slide-icon splide-slide-project10 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">Chevron TAM 2019</h1>
                                        <span className="mb-4">Overhaul and Recertification of PVSVs, Replacement
                                            and Reinstallation of Butterfly Valves and Actuators
                                        </span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                        </Splide>
                        <Splide
                            options={{
                                rewind: true,
                                gap: '1rem',
                                type: 'loop',
                                perPage: 5,
                                breakpoints: {
                                    1200: {
                                        perPage: 3,
                                        perMove: 1,
                                        autoplay: true,
                                    },
                                    768: {
                                        perPage: 1,
                                        perMove: 1,
                                        autoplay: true,
                                    },

                                },
                                perMove: 0,
                                autoplay: false,
                                interval: 5000,
                                arrows: false
                            }}
                            aria-label="My Favorite Images" X
                        >
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual last-row" data-bs-toggle="modal" data-bs-target="#elevenModal">
                                    <div className="splide-slide-icon splide-slide-project11 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">NLNG Area B Loading Arm Emergent Shutdown</h1>
                                        <span className="mb-4">Complete overhaul of 24-inch class 300 triple offset
                                            Vanessa butterfly valve
                                        </span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#twelveModal">
                                    <div className="splide-slide-icon splide-slide-project12 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">NLNG Train 3 VMS Project</h1>
                                        <span className="mb-4">Valve Overhaul, Accessories Change-out, Gland Packing Renewal, Actuator Overhaul, Re-assembling and Certifications
                                        </span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#thirteenModal">
                                    <div className="splide-slide-icon splide-slide-project13 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">NGC Egbin Project </h1>
                                        <span className="mb-4">Procurement, Installation and commissioning of
                                            Pressure Control Valves
                                        </span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#fourteenModal">
                                    <div className="splide-slide-icon splide-slide-project14 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">Modification of HIPPS Logic project for SPDC</h1>
                                        <span className="mb-4">Modification of HIPPS to trip in Emergency ESD </span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="col-11 mx-auto col-md mx-2 splide-slide-individual" data-bs-toggle="modal" data-bs-target="#fifteenModal">
                                    <div className="splide-slide-icon splide-slide-project15 col-12 px-0 text-center">

                                    </div>
                                    <div className="experience-hover col-11 text-center px-3">
                                        <h1 className="experience-title mb-2">NLNG Train 2 Valve Maintenance Service</h1>
                                        <span className="mb-4">Maintenance of valves and actuators</span> <br></br>
                                    </div>
                                </div>
                            </SplideSlide>
                        </Splide>
                        {/* <div className="col-12 text-end projects-details mb-5">
                            <a target='blank' href="https://docs.google.com/presentation/d/1cxz0l7LYY0Y6TAbziRadl9HQ8GboAVrX/edit?usp=sharing&ouid=110014617300953921991&rtpof=true&sd=true"
                             className="btn brand-btn-big">See all projects and details >></a>
                        </div> */}
                        {/* <div className="col-12 col-md projects-individual col border mx-2 px-0">
                        <div className="project-image col-12 border-bottom">

                        </div>
                        <div className="project-text col-12 text-center">
                            <h1 className="management-title mb-2">Daisy Maduagwu</h1>
                            <span className="mb-4">Deputy Managing Director Operations</span> <br></br>
                        </div>
                    </div>
                    <div className="col-12 col-md projects-individual col border mx-2"></div>
                    <div className="col-12 col-md projects-individual col border mx-2"></div>
                    <div className="col-12 col-md projects-individual col border mx-2"></div> */}
                    </div>
                </div>
            </div>
            <Footer />
            <div class="modal fade" id="oneModal" tabindex="-1" aria-labelledby="oneModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={gbaran_ubie} alt="gbaran-ubie-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>Gbaran Ubie PH3A Project</h1>
                                    <h6><span className="bold-text">Scope:</span> Valves testing and repair, Valve cage replacement</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Daewoo Nigeria Limited​</h6>
                                    <h6><span className="bold-text">End User:</span> Shell Petroleum Development Company of Nigeria Limited (SPDC)​</h6>
                                    <h6><span className="bold-text">Year:</span> 2022</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="twoModal" tabindex="-1" aria-labelledby="twoModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={obiafu} alt="obiafu-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>Obiafu 41 Development Project</h1>
                                    <h6><span className="bold-text">Scope:</span> HIPPS Installation & Commissioning</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Nigerian Agip Oil Company Ltd.​</h6>
                                    <h6><span className="bold-text">End User:</span> Nigerian Agip Oil Company Ltd.​</h6>
                                    <h6><span className="bold-text">Year:</span> 2022</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="threeModal" tabindex="-1" aria-labelledby="threeModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={madu} alt="madu-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>Madu OML 85 Field</h1>
                                    <h6><span className="bold-text">Scope:</span> HIPPS Commission for Madu Production Platfrom</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> First E&P​</h6>
                                    <h6><span className="bold-text">End User:</span> First E&P​</h6>
                                    <h6><span className="bold-text">Year:</span> 2022</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="fourModal" tabindex="-1" aria-labelledby="fourModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={nlng} alt="nlng-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>NLNG Train 4 valve maintenance service</h1>
                                    <h6><span className="bold-text">Scope:</span> Valve Overhaul, Accessories Change-out, Gland Packing Renewal, Actuator Overhaul, Re-assembling and Certifications</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Nigeria LNG Limited​</h6>
                                    <h6><span className="bold-text">End User:</span> Nigeria LNG Limited​</h6>
                                    <h6><span className="bold-text">Year:</span> 2021</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="fiveModal" tabindex="-1" aria-labelledby="fiveModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={calibration} alt="calibration-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>Calibration Services</h1>
                                    <h6><span className="bold-text">Scope:</span> Calibration of PCV in Olero and Abiteye</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Chevron Nigeria Limited​</h6>
                                    <h6><span className="bold-text">End User:</span> Chevron Nigeria Limited​</h6>
                                    <h6><span className="bold-text">Year:</span> 2021</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="sixModal" tabindex="-1" aria-labelledby="sixModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={anyala} alt="anyala-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>ANYALA & MADU (OML 83 & 85) Field Development Project</h1>
                                    <h6><span className="bold-text">Scope:</span> Supply & Commissioning of 3 units of HIPPS for Anyala & Madu Fields Development Project</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Aquaterra Energy​</h6>
                                    <h6><span className="bold-text">End User:</span> First E&P​</h6>
                                    <h6><span className="bold-text">Year:</span> 2020</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="sevenModal" tabindex="-1" aria-labelledby="sevenModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={eafod} alt="eafod-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>EAFOD Actuator Replacement and Calibration</h1>
                                    <h6><span className="bold-text">Scope:</span> Repair and Calibration of Choke Valve</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Shell Petroleum Development Company of Nigeria Limited (SPDC)​</h6>
                                    <h6><span className="bold-text">End User:</span> Shell Petroleum Development Company of Nigeria Limited (SPDC)​</h6>
                                    <h6><span className="bold-text">Year:</span> 2020</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="eightModal" tabindex="-1" aria-labelledby="eightModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={atmos} alt="atmos-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>ATMOS Portable Tightness Monitor commissioning</h1>
                                    <h6><span className="bold-text">Scope:</span> Commissioning of ATMOS Portable Tightness Monitor</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Total E & P​</h6>
                                    <h6><span className="bold-text">End User:</span> Total E & P​</h6>
                                    <h6><span className="bold-text">Year:</span> 2019</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="nineModal" tabindex="-1" aria-labelledby="nineModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={area_b} alt="area-b-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>Area B Emergent Shutdown (Valve Maintenance Services)</h1>
                                    <h6><span className="bold-text">Scope:</span> Accessories Change-out, Valve overhaul, Actuator Overhaul,
                                        Re-assembly & Testing as per VMS implementation</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> NLNG​</h6>
                                    <h6><span className="bold-text">End User:</span> NLNG</h6>
                                    <h6><span className="bold-text">Year:</span> 2019</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="tenModal" tabindex="-1" aria-labelledby="tenModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={chevron_tam} alt="chevron-tam-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>Chevron TAM 2019</h1>
                                    <h6><span className="bold-text">Scope:</span> Overhaul and Recertification of PVSVs, Replacement
                                        and Reinstallation of Butterfly Valves and Actuators</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Chevron Nigeria Limited​</h6>
                                    <h6><span className="bold-text">End User:</span> Chevron Nigeria Limited​</h6>
                                    <h6><span className="bold-text">Year:</span> 2019</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="elevenModal" tabindex="-1" aria-labelledby="elevenModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={nlng_area_b} alt="nlng-area-b-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>NLNG Area B Loading Arm Emergent Shutdown</h1>
                                    <h6><span className="bold-text">Scope:</span> Complete overhaul of 24-inch class 300 triple offset
                                        Vanessa butterfly valve</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> NLNG​</h6>
                                    <h6><span className="bold-text">End User:</span> NLNG​</h6>
                                    <h6><span className="bold-text">Year:</span> 2019</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="twelveModal" tabindex="-1" aria-labelledby="twelveModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={train3_vms} alt="train3-vms-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>NLNG Train 3 VMS Project</h1>
                                    <h6><span className="bold-text">Scope:</span> Valve Overhaul, Accessories Change-out, Gland Packing Renewal, Actuator Overhaul, Re-assembling and Certifications</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> NLNG​</h6>
                                    <h6><span className="bold-text">End User:</span> NLNG​</h6>
                                    <h6><span className="bold-text">Year:</span> 2019</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="thirteenModal" tabindex="-1" aria-labelledby="thirteenModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={egbin} alt="egbin-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>NGC Egbin Project </h1>
                                    <h6><span className="bold-text">Scope:</span> Procurement, Installation and commissioning of
                                        Pressure Control Valves
                                    </h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Nigerian Gas Company​</h6>
                                    <h6><span className="bold-text">End User:</span> NNPC​</h6>
                                    <h6><span className="bold-text">Year:</span> 2019</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="fourteenModal" tabindex="-1" aria-labelledby="fourteenModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={hipps_logic} alt="hipps-logic-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>Modification of HIPPS Logic project for SPDC</h1>
                                    <h6><span className="bold-text">Scope:</span> Modification of HIPPS to trip in Emergency ESD </h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Shell Petroleum Development Company of Nigeria Limited (SPDC)​</h6>
                                    <h6><span className="bold-text">End User:</span> Shell Petroleum Development Company of Nigeria Limited (SPDC) ​</h6>
                                    <h6><span className="bold-text">Year:</span> 2019</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="fifteenModal" tabindex="-1" aria-labelledby="fifteenModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0 text-center">
                            <div className="col-12 row mx-0 col-md-9 mx-auto project-details-modal">
                                <div className="col-12 col-md-4 project-modal-image pt-4 px-0">
                                    <img className="my-3" src={train2_valve} alt="train2-valve-project"></img>
                                </div>
                                <div className="col-12 col-md-8 project-modal-text text-left p-5">
                                    <h1>NLNG Train 2 Valve Maintenance Service</h1>
                                    <h6><span className="bold-text">Scope:</span> Maintenance of valves and actuators</h6> <br></br>
                                    <h6><span className="bold-text">Client:</span> Nigeria LNG Limited​</h6>
                                    <h6><span className="bold-text">End User:</span> Nigeria LNG Limited​</h6>
                                    <h6><span className="bold-text">Year:</span> 2018</h6>
                                    <h6><span className="bold-text">Status:</span> Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience;
