import React from "react";
import { Helmet } from "react-helmet";
import "./AutomationPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";

function Automation() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Automation & Control</title>
                <meta name="description" content="PE Energy has a long-standing experience in the execution of complex projects involving automation and system integration in the Oil & Gas industry. At PE Energy, we are committed to helping our clients achieve optimal productivity through state-of-the-art automation systems driven by cutting-edge technology." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="automation-image" pageTitle="Automation &amp; Control" pageDesc1="PE Energy has a long-standing experience in the execution of complex projects involving automation and system integration in the Oil & Gas industry.
             At PE Energy, we are committed to helping our clients achieve optimal productivity through state-of-the-art automation systems driven by cutting-edge technology." pageDesc2="Our reputation of reliability and trustworthiness is owed to our value-driven approach, our adherence to compliance and our persistence in the highest quality standards of competence and efficiency. At the same time, we aim to constantly develop and nurture long-term relations with our clients, keeping at their constant disposal our experts and our suggestions of sustainable strategies. We are a team of dynamic and customer-centric workforce that always aspire to stay one step ahead of potential challenges, we are intent on providing the best to our clients." />
            <div className="col-12 col-md-10 mx-auto mt-5 px-3">
                <h1 className="section-title mb-2 text-start">Experts in Critical <span classname="">Control & Safety</span></h1>
                <p className="col-12 col-md-10 mt-3 standard-text">For operations of all types on the industrial plant, the need for automation and control is high, especially in industries where safety and reliability are of vital importance. Over the past decades, the solutions of PE Energy have helped various key Oil & Gas players to improve their production processes, downtime, and profitability in line with their KPIs. We partner with multiple vendors as well as top software and hardware providers, to ensure our customers with best-in-class solutions.</p>
            </div>
            <div className="col-12 offerings-full py-5 my-5">
                <div className="col-12 col-md-10 mx-auto row mx-0">/
                    <h4 className="mb-4">Our Offerings</h4>
                    <ul class="list-group col-12 col-md list-group-flush">
                        <li class="list-group-item">Distributed Control Systems (DCS)</li>
                        <li class="list-group-item">Process Control Systems (PCS)</li>
                        <li class="list-group-item">Advanced Process Control</li>
                        <li class="list-group-item">Emergency Shutdown Systems (ESD)</li>
                        <li class="list-group-item">Fire and Gas Systems (F&G)</li>
                        <li class="list-group-item">Turbomachinery Control system (TMC)</li>
                        <li class="list-group-item">CCTV and Anti intrusion System</li>
                        <li class="list-group-item">Flow Metering and Custody Transfer Solutions</li>
                        <li class="list-group-item">Business KPI Dashboards/Visualization</li>
                    </ul>
                    <ul class="list-group col-12 col-md list-group-flush">
                        <li class="list-group-item">Supervisory Control and Data Acquisition System (SCADA) for Pipelines</li>
                        <li class="list-group-item">Safety Instrumented Systems (SIS) – SIL II and III compliant</li>
                        <li class="list-group-item">Emergency Shutdown Systems (ESD)</li>
                        <li class="list-group-item">Truck Loading Automation System (TLAS)</li>
                        <li class="list-group-item">Terminal Automation System (TAS)</li>
                        <li class="list-group-item">Pipeline Leak Detection System</li>
                        <li class="list-group-item">Plant Information Management System (PIMS)</li>
                        <li class="list-group-item">ERP Connectivity</li>
                    </ul>
                    <ul class="list-group col-12 col-md list-group-flush">
                        <li class="list-group-item">Alarm Management System (AMS)</li>
                        <li class="list-group-item">Control Loop Tuning & Optimization</li>
                        <li class="list-group-item">After Market Services and Support</li>
                        <li class="list-group-item">Turnkey EPC Services for Electrical and Instrumentation Works</li>
                        <li class="list-group-item">Supervisory Control and Data Acquisition System (SCADA) for Wellheads and Plants</li>
                        <li class="list-group-item">Wellhead Control Panels (WHCP) –Hydraulic and Pneumatic</li>
                        <li class="list-group-item">Remote Terminal Units (RTUs) for Wellheads</li>
                        <li class="list-group-item">Solar panel package for Wellhead Control Panels</li>
                    </ul>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Automation;
