import logo from "./logo.svg";

import "./App.css";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPageComponent/LandingPage";
import AboutUs from "./pages/AboutUsComponent/AboutUsPage";
import Summary from "./pages/SummaryComponent/SummaryPage";
import Valves from "./pages/ValvesComponent/ValvesPage";
import Measurement from "./pages/MeasurementComponent/MeasurementPage";
import Automation from "./pages/AutomationComponent/AutomationPage";
import Process from "./pages/ProcessComponent/ProcessPage";
import Exploration from "./pages/ExplorationComponent/ExplorationPage";
import Supply from "./pages/SupplyComponent/SupplyPage";
import WaterProcess from "./pages/WaterProcessComponent/WaterProcessPage";
import MarketFocus from "./pages/MarketFocusComponent/MarketFocusPage";
import Careers from "./pages/CareersComponent/CareersPage";
import ContactUs from "./pages/ContactUsComponent/ContactUsPage";
import Sustainability from "./pages/SustainabilityComponent/SustainabilityPage";
import EPiiMtce from "./pages/EPiiMtceComponent/EPiiMtcePage";
import DigitalSolutions from "./pages/DigitalSolutionsComponent/DigitalSolutionsPage";
import NotFoundPage from "./pages/NotFoundComponent/NotFoundPage";
import Experience from "./pages/ExperienceComponent/ExperiencePage";
import FAQ from "./pages/FAQ/FAQ";

function App() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 page-full px-0">
          <HashRouter>
            <Routes>
              <Route path="/" exact element={<LandingPage />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/epiimtce" element={<Summary />} />
              <Route path="/valves" element={<Valves />} />
              <Route path="/measurement" element={<Measurement />} />
              <Route path="/automation" element={<Automation />} />
              <Route path="/process" element={<Process />} />
              {/* <Route path="/exploration" element={<Exploration />} /> */}
              <Route path="/supply" element={<Supply />} />
              <Route path="/water-process" element={<WaterProcess />} />
              <Route path="/market-focus" element={<MarketFocus />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/sustainability" element={<Sustainability />} />
              <Route path="/epiimtce" element={<EPiiMtce />} />
              {/* <Route path="/digital-solutions" element={<DigitalSolutions />} /> */}
              <Route path="/experience" element={<Experience />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/404" element={<NotFoundPage />} />
              {/* <Route path='/insights' component={() => {
                window.location.href = 'https://insights.pe-ng.com/';
                return null;
              }} /> */}
            </Routes>
          </HashRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
