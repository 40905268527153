import React from 'react'
import "./Footer.css"
import { Link } from "react-router-dom"
import logoWhite from '../../images/pe-logo-white.png';
import veritas from '../../images/veritas.png';
import trace from '../../images/trace.png';
import nitda from '../../images/nitda.png';
import twitter from '../../images/twitter.png';
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import linkedin from '../../images/linkedin.png';
import { useEffect } from "react";
import { useLocation } from "react-router";
import protectionpdf from "../../images/Policies/data-protection.pdf";
import privacypdf from "../../images/Policies/data-privacy.pdf";
import CookieConsent from "react-cookie-consent";

function Footer(props) {
  return (
    <div className="col-12 footer-full mt-5">
      <div className="footer-actual col-12 col-md-9 row mx-auto">
        <div className="footer-sections footer-company-info col-12 col-md">
          <img className='mt-4 my-5' src={logoWhite} alt="logo" />
          {/* <p>
            Automation and Digitalization is one of the main areas requiring intervention by a specialist service provider with a balanced approach. <br></br><br></br>
            We are experts in offering automation and control systems integration services for various industries. <br></br><br></br>
            Customers are keen on a company that has a global view and a local perspective along with the ability to offer state-of-the-art Control and Automation solutions which will help them streamline their processes,
            improve their production, minimize downtime and maximize profitability in line with their KPIs.
          </p> */}
          <ul class="list-group list-group-flush">
            <Link to="/" className="nav-link" href="#">
              <li class="list-group-item py-1 list-title">Home</li>
            </Link>
            <Link to="/about" className="nav-link" href="#">
              <li class="list-group-item py-1">Who We Are</li>
            </Link>
            <Link to="/summary" className="nav-link" href="#">
              <li class="list-group-item py-1">Growth Engines</li>
            </Link>
            <Link to="/market-focus" className="nav-link" href="#">
              <li class="list-group-item py-1">Market Focus</li>
            </Link>
            <Link to="/sustainability" className="nav-link" href="#">
              <li class="list-group-item py-1">Sustainability</li>
            </Link>
            <li class="list-group-item py-1">
              <a className="nav-link" target="_blank" rel="noreferrer" href="https://insights.pe-ng.com/">Insights</a>
            </li>
            <Link to="/careers" className="nav-link" href="#">
              <li class="list-group-item py-1">Careers</li>
            </Link>
            <Link to="/contact-us" className="nav-link" href="#">
              <li class="list-group-item py-1">Contact Us</li>
            </Link>
            <Link to="/faq" className="nav-link" href="#">
              <li class="list-group-item py-1">FAQ</li>
            </Link>
          </ul>
        </div>
        <div className="footer-sections footer-company-info col-12 col-md px-5 pt-5">
          <b className=''>PE Energy Limited</b>
          <p className='city-located mt-2'>Port Harcourt, Nigeria</p>
          <small className=''>Centre of Excellence: Plot 94 Rivoc Road, Trans Amadi Industrial Layout, Port Harcourt, Rivers State.</small><br></br>
          <small className=''>No 54 Emekuku Street, D-Line, Port Harcourt, Rivers State, Nigeria.</small><br></br>
          <small className=''>Tel: +234 702 600 0116</small><br></br><br></br>
          <p className='city-located mt-0'>Abuja, Nigeria</p>
          <small className=''>No. 5 Vattern Street, off Amazon Street, Maitama, Abuja, FCT, Nigeria</small><br></br>
          <small className=''>Tel: + 234 702 600 0119</small><br></br><br></br>
          <div className='col-12 row footer-policies'>
            <div data-bs-toggle="modal" data-bs-target="#protectionModal" className="col-12 col-lg-6 mx-auto my-2 px-0">
              <a href={protectionpdf} target="_blank" rel="noreferrer">
                <span>Data Protection Policy</span>
              </a>
            </div>
            <div data-bs-toggle="modal" data-bs-target="#privacyModal" className="col-12 col-lg-6 mx-auto my-2 px-0">
              <a href={privacypdf} target="_blank" rel="noreferrer">
                <span>Data Privacy Policy</span>
              </a>
            </div>
            {/* <div class="modal fade" id="protectionModal" tabindex="-1" aria-labelledby="protectionModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body p-0 text-center">
                    <div className="modal-head py-4">

                      <h6>Our Data Protection Policy</h6>
                    </div>
                    <div className="modal-body px-5">
                      <p>dadvsva</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="privacyModal" tabindex="-1" aria-labelledby="privacyModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body p-0 text-center">
                    <div className="modal-head py-4">

                      <h6>Our Data Privacy Policy</h6>
                    </div>
                    <div className="modal-body px-5">
                      <p>dadvsva</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer-sections footer-company-info col-12 col-md pt-5">
          <p className='city-located mt-0 mt-4'>Kampala, Uganda</p>
          <small className=''>C/O Cristal Advocates, 4th Floor, Padre Pio House,<br></br>Plot 32 Lumumba Avenue, Kampala, Uganda. P.O Box 1769</small><br></br><br></br>
          <p className='city-located mt-0'>Dubai, U.A.E</p>
          <small className=''>Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E</small><br></br>
          <div className='my-3'>
            <small className=''>Email: info@pe-ng.com</small><br></br>
            <div className='col-12 social-icons-box mt-3'>
              <a href="https://www.facebook.com/peenergyltd" target="_blank"><img className='col my-2 social-icons first-icon' src={facebook} alt="facebook" /></a>
              <a href="https://www.linkedin.com/company/18207397/" target="_blank"><img className='col my-2 social-icons' src={linkedin} alt="linkedin" /></a>
              <a href="https://twitter.com/peenergyltd" target="_blank"><img className='col my-2 social-icons' src={twitter} alt="twitter" /></a>
              <a href="https://www.instagram.com/peenergyltd/" target="_blank"><img className='col my-2 social-icons' src={instagram} alt="instagram" /></a>
              
            </div>

          </div>
        </div>
      </div>
      <div className='footer-licenses col-12 row mx-auto border'>
        <p className='col-12 col-md-5 mt-4 ms-5'>© 2020 PE Energy - <span className="orange-text">Grace at work</span>. All rights reserved.</p>
        <div className='col-12 col-md-5 ms-auto row mx-0'>
          <img className='col my-2' src={trace} alt="trace" />
          <img className='col my-2 nitda' src={nitda} alt="nitda" />
          <img className='col my-2' src={veritas} alt="veritas" />
        </div>
      </div>

      <CookieConsent>This website uses cookies to enhance the user experience. For more information, read our
        <a className='data-links' href={protectionpdf} target="_blank" rel="noreferrer"> <span>Data Protection</span></a> and <a className='data-links' href={privacypdf} target="_blank" rel="noreferrer"><span>Data Privacy</span></a> Policies.

      </CookieConsent>
    </div>
  )
}

export default Footer
