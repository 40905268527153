import React from "react";
import { Helmet } from "react-helmet";
import "./NotFoundPage.css";
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom"

function NotFoundPage() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div className="not-found">
            <Helmet>
                <meta charSet="utf-8" />
                <title>404</title>
                <meta name="description" content="As an Engineering, Procurement, Integration, Installation, and Maintenance company, the purpose of the Organization is to provide quality services in Automation & Control, Valves & Actuation, Process Solutions, Flow Measurement Solutions, Electrical & Instrumentation Solutions, and Supply Chain Services to all our Customers.e" />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            {/* <NavBar /> */}
            <div class="page-wrap d-flex flex-row align-items-center">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12 text-center">
                            <span class="display-1 d-block">404</span>
                            <div class="mb-4 lead">Oh no! We couldn't find the page you're looking for. </div>
                            <div className="col-12 col-md-6 row mx-0 mx-auto">
                                <Link to="/" className="nav-link col" href="#">
                                    <a class="nav-link" href="#">Back to Home</a>
                                </Link>
                                <a className="nav-link col" target="_blank" href="https://insights.pe-ng.com/">News</a>
                                <Link to="/contact-us" className="nav-link col" href="#">
                                    <a class="nav-link" href="#">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

export default NotFoundPage;
