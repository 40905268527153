import React from "react";
import { Helmet } from "react-helmet";
import "./SustainabilityPage.css";
// import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import people from "../../images/people.png"
import ribbon from "../../images/ribbon.png"
import maxim from "../../images/maxim.png"
import { useEffect } from "react";
import { useLocation } from "react-router";

function Sustainability() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sustainability</title>
                <meta name="description" content="An Engineering Procurement, Integration, Installation and Maintenance company" />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="sustainability-image" pageTitle="Sustainability" pageDesc1="" pageDesc2="" />
            <div className="col-12 px-3 col-md-10 mx-auto mt-5 Sustainability-full">
                <div className="col-12 col-md-10 mx-0 row mx-auto">
                <h1 className="section-title mb-3 text-start mx-auto">Committed to the <span classname="">Planet</span></h1>
                <p className="col-12 col-md-10 mt-3 standard-text"> In line with our Operating Maxim: The 5 Ps (Purpose for Sustainability, Process and Technology, People and Talent, Products & Solutions, Profit for Employees, Stakeholders and Shareholders), at the forefront of all our operations it is ensured that our Planet is respected, protected, preserved. <br></br><br></br> 
                At PE Energy, we understand that while meeting our own needs is important, it is even more important that future generations be able to meet theirs.</p>
                {/* <div className="col-12 col-md-7 my-5">
                
                </div> */}
                <img className="maxim-image col-12 col-md-7 my-5" src={maxim} alt="maxim" />
                <h1 className="section-title mb-3 text-start mx-auto">Committed to our <span classname="">Society</span></h1>
                <p className="col-12 col-md-10 mt-3 standard-text">Additionally, we remain committed to the people and host communities where we operate. We're committed to the maintenance of resources in these communities as we recognise this as a vital partnership. <br></br><br></br> 
                Another crucial aspect of our sustainability focused mindset is upholding gender and social equality. It is extremely important to us that every individual is provided with the resources to secure their future. These principles are at the backbone of all our operations.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Sustainability;
