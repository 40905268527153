import React from 'react'
import "./NavBar.css"
import { Link } from "react-router-dom"
import logo from '../../images/pe-logo-white-text.png';
import epiimtce from '../../images/epiimtce_logo_white.png';

function NavBar() {
  function clickedWhoWeAre() {
    localStorage.setItem('clickedLink', '')
  }
  function clickedPrinciples() {
    localStorage.setItem('clickedLink', 'principles')
  }
  function clickedManagement() {
    localStorage.setItem('clickedLink', 'management')
  }
  function clickedHistory() {
    localStorage.setItem('clickedLink', 'history')
  }
  function clickedPolicies() {
    localStorage.setItem('clickedLink', 'policies')
  }
  return (
    <nav class="navbar col-12 navbar-expand-xl navbar-dark">
      <div class="container-fluid col-12 col-xl-10">
        <a class="navbar-brand" href="#"><img src={logo} alt="logo" /></a><br></br>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto me-5">
            <Link to="/" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">Home</a>
              </li>
            </Link>
            {/* <Link to="/about" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link" href="#">Who We Are</a>
              </li>
            </Link> */}
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" id="solutionsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                About Us
              </a>
              <div class="dropdown-menu" aria-labelledby="solutionsDropdown">
                <Link onClick={clickedWhoWeAre} to="/about" className="nav-link" href="#">
                <a class="dropdown-item mb-3" href="#"> Who We Are</a>
                </Link>
                <Link onClick={clickedPrinciples} to="/about" className="nav-link" href="#">
                <a class="dropdown-item mb-3" href="#"> -- Our Principles</a>
                </Link>
                <Link onClick={clickedManagement} to="/about" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Our Leadership</a>
                </Link>
                <Link onClick={clickedHistory} to="/about" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Our History</a>
                </Link>
                <Link onClick={clickedPolicies} to="/about" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Our Policies</a>
                </Link>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" id="solutionsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Our Solutions
              </a>
              <div class="dropdown-menu" aria-labelledby="solutionsDropdown">
                {/* <Link to="/summary" className="nav-link" href="#">
                  <a class="dropdown-item mb-3 top-level" href="#">Sales and Services Solutions</a>
                </Link> */}
                <Link to="/epiimtce" className="nav-link" href="#">
                  <a class="dropdown-item mb-3 top-level" href="#"><img className="epiimtce-logo margin-top-minus-5" src={epiimtce} alt="epiimtce" /></a>
                </Link>
                {/* <Link to="/automation" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Automation (E&I) &amp; Control</a>
                </Link> */}
                <Link to="/process" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Flow Drives (Rotating Equipment)</a>
                </Link>
                <Link to="/measurement" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Flow Measurement Solutions</a>
                </Link>
                <Link to="/valves" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Flow Control (Valves & Actuation)</a>
                </Link>
                <Link to="/supply" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#"> -- Supply Chain Management</a>
                </Link>
                
                  {/* <a class="dropdown-item mb-3" href="https://syn-ep.com/">Exploration &amp; Production Solutions</a>
             
                
                  <a class="dropdown-item mb-3" href="https://akd-digital.com/">Digital Solutions</a> */}
                  <a class="dropdown-item mb-3" href="https://dklok-ng.com/">DK-Lok Nigeria</a>
                {/* <Link style={{pointerEvents: "none"}} to="/supply" className="nav-link" href="#">
                  <a id='disabled-link' class="dropdown-item mb-3" href="#"> -- Hydraulics (Coming Soon)</a>
                </Link> */}
                {/* <li><hr class="dropdown-divider"></hr></li>
        
                <li><hr class="dropdown-divider"></hr></li> */}
              </div>
            </li>

            {/* <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" id="domainSolutionsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                DOMAIN SOLUTION
              </a>
              <div class="dropdown-menu" aria-labelledby="domainSolutionsDropdown">
                <Link to="/water-process" className="nav-link" href="#">
                  <a class="dropdown-item mb-3" href="#">Water and Process Technologies</a>
                </Link>
              </div>
            </li> */}
            <Link to="/experience" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link" href="#">Our Experience</a>
              </li>
            </Link>
            <Link to="/market-focus" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link" href="#">Market Focus</a>
              </li>
            </Link>
            <Link to="/sustainability" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link" href="#">Sustainability</a>
              </li>
            </Link>
            <li class="nav-item">
              <a className="nav-link" target="_blank" href="https://insights.pe-ng.com/">Insights</a>
            </li>
            <Link to="/careers" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link" href="#">Careers</a>
              </li>
            </Link>
            <Link to="/faq" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link" href="#">FAQ</a>
              </li>
            </Link>
            <Link to="/contact-us" className="nav-link" href="#">
              <li class="nav-item">
                <a class="nav-link" href="#">Contact Us</a>
              </li>
            </Link>







          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
