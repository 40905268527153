import React from "react";
import { Helmet } from "react-helmet";
import "./SupplyPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import { useEffect } from "react";
import { useLocation } from "react-router";

function Supply() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Supply Chain & Project Management</title>
                <meta name="description" content="PE Energy Limited has successfully delivered various items to different industries within and outside Nigeria. We take pride in our long-standing partnerships with various Original Equipment Manufacturers (OEMs) across the globe. We have strategic alliances with stockiest, buying houses and manufactures around the world which further help us deliver quality services in the field of the supply chain. Through our experience, resources, and expertise in procurement, we offer cost effective services and a seamless experience for our customer." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="supply-image" pageTitle="Supply Chain Management" pageDesc1="PE Energy Limited has successfully delivered various items to different industries within and outside Nigeria." pageDesc2="We take pride in our long-standing partnerships with various Original Equipment Manufacturers (OEMs) across the globe. We have strategic alliances with stockiest, buying houses and manufactures around the world which further help us deliver quality services in the field of the supply chain. Through our experience, resources, and expertise in procurement, we offer cost effective services and a seamless experience for our customer." />
            <div className="col-12 col-md-10 mx-auto mt-5 px-3">
                <p className="col-12 col-md-10 mt-3 standard-text">Our Supply Chain Management team works smoothly with our various partners to ensure the impeccable delivery of our wide range of products and services. Our objective is to give the best services across the various industry segments like Cement, Oil and Gas, Food and Beverages, Power Industry, Energy, and we have a track record of handling various complex projects that deliver value to our customers. <br></br><br></br>We understand that every project has multiple tasks that need to be handled effectively and efficiently. Our skilled team members are aware of the various case scenarios and are equipped with the right tool as well as capacity to handle the various project requirements, by adhering to the various standards set by the authorities and the specific customer need. As a result, our clients profit from our value-added services, stemming from our in-depth knowledge of the supply chain process and clock-like precision in project management.</p>
            </div>
            <div className="col-12 offerings-full py-5 my-5"> 
                <div className="col-10 mx-auto row mx-0">
                    <h4 className="mb-4">Our Offerings</h4>
                    <ul class="list-group col-12 col-md list-group-flush">
                        <li class="list-group-item">Pipes</li>
                        <li class="list-group-item">Fittings</li>
                        <li class="list-group-item">Flanges</li>
                        <li class="list-group-item">Valves and Spares</li>
                        <li class="list-group-item">Electrical / Instrumentation</li>
                        <li class="list-group-item">Pumps and Spares</li>
                        <li class="list-group-item">Compressors</li>
                    </ul>
                    <ul class="list-group col-12 col-md list-group-flush">
                        <li class="list-group-item">Parts</li>
                        <li class="list-group-item">Heavy Plant Machinery</li>
                        <li class="list-group-item">Heat Exchangers</li>
                        <li class="list-group-item">Rotating equipment Spare</li>
                        <li class="list-group-item">Lightening equipment’s and Fittings</li>
                        <li class="list-group-item">Gaskets, Seals and O-rings</li>
                        <li class="list-group-item">Machine Spares</li>
                    </ul>
                    <ul class="list-group col-12 col-md list-group-flush">
                        <li class="list-group-item">Fire and Gas Equipment</li>
                        <li class="list-group-item">Security Equipment’s</li>
                        <li class="list-group-item">Balance of Plant equipment’s</li>
                        <li class="list-group-item">Drilling equipment’s,</li>
                        <li class="list-group-item">Tools & Tackles</li>
                        <li class="list-group-item">Safety Products and PPEs</li>
                        <li class="list-group-item">MRO spares</li>
                    </ul>

                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Supply;
