import React from 'react'
import "./PageBanner.css"
import { Link } from "react-router-dom"
import logo from '../../images/pe-logo.png';

function PageBanner(props) {
    let classList = 'col-12 px-0 page-banner';
    let classList2 = 'col-12 pageDesc';
    console.log(props.pageImage)
    if (props.pageImage == 'pe-sign') {
        classList += ' pe-sign'
    } else if (props.pageImage == 'valve-image') {
        classList += ' valve-image'
    } else if (props.pageImage == 'measurement-image') {
        classList += ' measurement-image'
    } else if (props.pageImage == 'automation-image') {
        classList += ' automation-image'
    } else if (props.pageImage == 'process-image') {
        classList += ' process-image'
    } else if (props.pageImage == 'exploration-image') {
        classList += ' exploration-image'
    } else if (props.pageImage == 'supply-image') {
        classList += ' supply-image'
    } else if (props.pageImage == 'water-image') {
        classList += ' water-image'
    } else if (props.pageImage == 'market-image') {
        classList += ' market-image'
    } else if (props.pageImage == 'careers-image') {
        classList += ' careers-image'
    } else if (props.pageImage == 'sustainability-image') {
        classList += ' sustainability-image'
    } else if (props.pageImage == 'epiimtce-image') {
        classList += ' epiimtce-image'
    } else if (props.pageImage == 'digital-image') {
        classList += ' digital-image'
    } else if (props.pageImage == 'faq-image') {
        classList += ' faq-image'
    }
    else classList = 'col-12 px-0 page-banner pe-sign';

    if (props.pageDesc1 === "" && props.pageDesc2 === "") {
        classList2 += ' d-none'
    }

    return (
        <div className='pageBanner-full'>
            <div className={classList}>
                <div className="col-12 col-md-10">
                    <h1 className="mb-5">{props.pageTitle}</h1>
                    {/* <p>{props.pageDesc1}</p>
                <p>{props.pageDesc2}</p> */}
                </div>
            </div>
            <div className={classList2}>
                <div className='text-con col-12 col-md-7 mx-auto'>
                    <p className='desc1'>{props.pageDesc1}</p>
                    <p className='desc2'>{props.pageDesc2}</p>
                </div>
            </div>
        </div>
    )
}

export default PageBanner
