import React from "react";
import { Helmet } from "react-helmet";
import "./CareersPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import people from "../../images/people.png"
import ribbon from "../../images/ribbon.png"
import { useEffect } from "react";
import { useLocation } from "react-router";

function Careers() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Careers</title>
                <meta name="description" content="Build a Career at PE Energy." />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="careers-image" pageTitle="Build a Career at PE Energy" pageDesc1="We recognize that our people are our most valued asset." pageDesc2="At PE Energy Limited we take great pride in the professionalism, integrity and honesty that we bring to all our business partnerships. These are the values that form the basis of our work culture." />
            <div className="col-12 careers-full">
                {/* <div className="col-12 col-md-7 row mx-0 mt-5 mx-auto">
                    <img className="mx-auto" src={people} alt="people"></img>
                    <span className="col-12 col-md px-5">
                        <b>We put people first</b> <br></br><br></br>
                        <span classname="">Having talented and passionate employees is, without doubt, the best way to transform ideas and targets into outstanding results, which is why we place such great importance on their performance and well-being.<br></br><br></br> In turn, we look to attract innovative thinkers who are inquisitive, proactive and willing to grow with us, so that we can continue to develop new and exciting solutions for our clients.</span>
                    </span>
                </div>
                <div className="col-12 col-md-7 row mx-0 mt-5 mx-auto">
                    <img className="mx-auto" src={ribbon} alt="people"></img>
                    <span className="col-12 col-md px-5">
                        <b>Rewards and benefits</b> <br></br><br></br>
                        <span classname="">In return for this dedication, commitment and responsibility, PE Energy offers an attractive rewards and benefits package which include:<br></br><br></br>
                            <li className="mb-2">Competitive financial packages</li>
                            <li className="mb-2">Training and development</li>
                            <li className="mb-2">Talent management</li>
                            <li className="mb-2">Retirement plans</li>
                            <li className="mb-2">Reward and recognition programs</li>
                            <li className="mb-2">Free Medicals (Employee and Dependents)</li>
                        </span>
                    </span>
                </div> */}
                <div className="available-positions my-5">
                    {/* <h1 className="section-title mb-5 text-center">Available <span classname="">Positions</span></h1> */}
                    {/* <p className="standard-text text-center"> There are no available positions at this time</p> */}
                    {/* <iframe className="col-12 careers-iframe" src="https://www.careers-page.com/pe-energy-limited-2"></iframe> */}
                    <div className="col-12 col-md-5 px-2 mx-auto">
                        <p className="text-center p-3 careers-enquiries-text mb-5">For enquiries regarding Careers, send an email to <a href="mailto: hr@pe-ng.com">hr@pe-ng.com</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Careers;
