import { Helmet } from "react-helmet";
import "./ContactUsPage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import call from "../../images/call.png"
import email from "../../images/email.png"
import visit from "../../images/visit.png"
import { useEffect } from "react";
import { useLocation } from "react-router";

// function ContactUs() {
//     return (
//         
//     );
// }

// export default ContactUs;

// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);  
    }, [location]);
    const [state, handleSubmit] = useForm("xqknwlwo");
    if (state.succeeded) {
        return <p>Thanks for joining!</p>;
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us</title>
                <meta name="description" content="Leave a message and we'll get back to you as soon as possible!" />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="pe-sign" pageTitle="Contact Us" pageDesc1="" pageDesc2="" />
            <div className="col-12 col-md-9 mx-auto contactus-full pt-5 row mx-0">
                <div className="col-12 col-lg-10 col-xl-9 mx-auto map-locations">

                </div>
                <div className="col-12 col-md-5 form-con">
                    <p className="standard-text color-brand-blue pt-5 mb-2">Leave a message and we'll get back to you as soon as possible! <br></br><br></br></p>
                    <form className="col-12" onSubmit={handleSubmit}>
                        <div className="col-12 mb-3">
                            <label className="standard-text" htmlFor="email">Full Name</label> <br></br><br></br>
                            <input class="form-control" id="name" type="text" name="Full Name" />
                        </div>
                        <div className="col-12 mb-3">
                            <label className="standard-text" htmlFor="email">Email Address</label> <br></br><br></br>
                            <input class="form-control" id="email" type="email" name="Email Address" />
                            <ValidationError prefix="Email" field="email" errors={state.errors} />
                        </div>
                        <div className="col-12 mb-3">
                            <label className="standard-text" htmlFor="email">Phone Number</label> <br></br><br></br>
                            <input class="form-control" id="phone" type="phone" name="Phone Number" />

                        </div>
                        <div className="col-12 mb-3">
                            <label className="standard-text" htmlFor="message">Message</label> <br></br><br></br>
                            <textarea class="form-control" id="message" name="Message from Sender" />
                            <ValidationError prefix="Message" field="message" errors={state.errors} />
                        </div>

                        <button className="btn brand-btn mt-5" type="submit" disabled={state.submitting}>
                            Send
                        </button>
                    </form>
                </div>
                <div className="col-12 col-md-7 ps-5 reach-directly">
                    <p className="standard-text color-brand-blue py-5 ms-5">Or reach us directly on any of the following channels!</p>
                    <div className="col-12 row mx-0 mt-5">
                        <img className="" src={call} alt="call"></img>
                        <span className="col px-5 channels">
                            <b>Call Us</b> <br></br><br></br>
                            <span className="">+234 702 600 0116 <br></br>+234 702 600 0119</span>
                        </span>
                    </div>
                    <div className="col-12 row mx-0 mt-5 mx-auto">
                        <img className="" src={email} alt="email"></img>
                        <span className="col px-5 channels">
                            <b>Email Us</b> <br></br><br></br>
                            <span className="">info@pe-ng.com</span>
                        </span>
                    </div>
                    <div className="col-12 row mx-0 mt-5 mx-auto">
                        <img className="" src={visit} alt="visit"></img>
                        <span className="col px-5 channels">
                            <b>Visit Us</b> <br></br><br></br>
                            <span className="">Centre of Excellence: Plot 94 Rivoc Road, Trans Amadi Industrial Layout, Port Harcourt, Rivers State.</span><br></br><br></br>
                            <span className="">No 54 Emekuku Street, D-Line, Port Harcourt, Rivers State, Nigeria.</span><br></br><br></br>
                            <span className="">No. 5 Vattern Street, off Amazon Street, Maitama, Abuja, FCT, Nigeria</span><br></br><br></br>
                            <span className="">C/O Cristal Advocates, 4th Floor, Padre Pio House, Plot 32 Lumumba Avenue, P.O Box 1769, Kampala, Uganda</span><br></br><br></br>
                            <span className="">Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E</span><br></br><br></br>

                        </span>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default ContactForm;
