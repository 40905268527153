import React from "react";
import { Helmet } from "react-helmet";
import "./EPiiMtcePage.css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import NavBar from "../../components/NavBarComponent/NavBar";
import Footer from "../../components/FooterComponent/Footer";
import PageBanner from "../../components/PageBannerComponent/PageBanner";
import people from "../../images/people.png"
import ribbon from "../../images/ribbon.png"
import { useEffect } from "react";
import { useLocation } from "react-router";

function EPiiMtce() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>EPiiMtce</title>
                <meta name="description" content="An Engineering Procurement, Integration, Installation and Maintenance company" />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <NavBar />
            <PageBanner pageImage="epiimtce-image" pageTitle="EPiiMtce" pageDesc1="" pageDesc2="" />
            <div className="col-12 EPiiMtce-full">
                
            </div>
            <Footer />
        </div>
    );
}

export default EPiiMtce;
